@use '../../assets/sass/others/variables' as theme;

@use "sass:map";

$color_1: rgb(243, 104, 74);
$color_2: black;
$color_3: rgba(0, 0, 0, 0.6);


.inner-product{
    
    font-family: Outfit;
    
    .introduction-section {
    
        display: flex;
        width: 100%;
        padding: 120px 0px;
        background-color: #F9F9F9;

    
        .section-container {

            width: 100%;
            max-width: 1600px;
            display: flex;
            flex-direction: row;
            margin: auto;
    
            .container-content {
                margin-left: 130px;
                max-width: 600px;
                width: 100%;

                .platform{
                    transform: scale(1);
                    margin-top: 20px;
                    margin-left: -40px;
                }
    
                .label {
                    font-family: Outfit;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 22.14px;
                }
    
                .title {
                    font-size: 50px;
                    font-weight: 300;
                    line-height: 65px;
                    color: rgb(90, 83, 107);
    
                    span {
                        background: -webkit-linear-gradient(99.92deg, #67B094 7.85%, #BAD48A 57.45%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
    
                .description {
                    font-family: Outfit;
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                    color:#4D5460;
                }
    
                .container-image {
                    display: none;
                }

                .bullet-point-container{
                    display: flex;
                    flex-direction: column;
                    .bullet-item{
                        display: flex;
                        flex-direction: row;
                        padding: 5px;

                        .icon-container{
                            display: flex;
                            flex-direction: column;

                            .buttet-icon{
                                margin-top: 5px;
                                width: 15px;
                                height: 15px;
                            }
                        }

                        .bullet-text{
                            margin-left: 8px;
                            font-family: Outfit;
                            font-weight: 300;
                            font-size: 18px;
                            line-height: 1.5;
                            letter-spacing: 0em;
                            text-align: left;
                            color: rgba(0, 0, 0, 0.6);
                        }
                    }
                }
    
            }
    
            .container-image {
                width: 100%;
                display: flex;
    
                .image-container {
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: fit-content;
                    margin-left: auto;
                    right: 140px;

                    .display-image {
                        position: relative;
                        width: 450px ;
                        height:  450px ;
                        transform: scale(1.3);
                    }
                }
    
            }

            .botton-container {
                margin-top: 60px !important;
            }
        }
    
    }

    .product-section {

        padding: 50px 20px;
    
    
        .section-container{
    
            margin: auto;
            width: 100%;
            height: 100%;
            display: flex;
            max-width: 1600px;
            flex-direction: column;
            position: relative;

            .title {
                font-size: 50px;
                font-weight: 300;
                color: rgb(90, 83, 107);
                text-align: center;
                margin-bottom: 0rem;
                padding-bottom: 40px;

                span {
                    color: rgb(243, 104, 74);
                }

            }

            .description{
                font-family: Outfit;
                font-size: 18px;
                font-weight: 300;
                line-height: 27px;
                letter-spacing: 0em;
                text-align: center;

                width: 900px ;
                margin: auto;
                padding-bottom: 80px;
            }

            .inner-container{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 1210px;
                height: 1240px;
                margin: auto;
                padding-bottom: 40px;

                .image-container{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
            }

            .image-one{
                width:1210px;
                height:574px;
                border-radius: 6px;
            }
    
            .image-two{
                width: 563px;
                height: 574px;
                border-radius: 6px;

            }
        }
    
    }

    .contact-center-solution{
        padding-top: 100px;

        .section-container{
            margin: auto;
            width: 100%;
            height: 100%;
            display: flex;
            max-width: 1600px;
            flex-direction: column;
            position: relative;

            .card-container {
                padding-top: 20px;
                position: relative;
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: center;

                .card {
                    font-family: Outfit;
                    background-color: #F9F9F9;
                    font-weight: 300;
                    font-size: 18px;
                    text-align: left;
                    line-height: 27px;
                    width: 400px;
                    height: 280px;
                    color: rgba(58, 49, 76, 0.8);
                    margin-left: 15px;
                    margin-bottom: 30px;
                    margin-top: 30px;
                    border-radius: 6px;
                    padding: 20px;
                    border: none;
                    outline: none;
                    // box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
    
                    .card-icon {
                        margin-left: 10px;
                        position: absolute;
                        margin-top: -55px;
                        width: 70px;
                        height: 70px;
                    }
    
                    .title {
                        font-family: Outfit;
                        font-weight: 600;
                        font-size: 22px;
                        text-align: left;
                        color: rgb(58, 49, 76);
                        padding-top: 20px;
                    }
                }
            
            }

            .title{
                font-family: Outfit;
                font-size: 50px;
                font-weight: 300;
                line-height: 65px;
                width: 100%;
                letter-spacing: 0em;
                text-align: center;
    
                span{
                    font-weight: 600;
                }
    
            }
        }

    }

    .explore-other-products-section{
        padding: 60px 0px;
        background-color: #F9F9F9;

        .section-container{

            .inner-container{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 1210px;
                height: fit-content;
                margin: auto;

                .image-container{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .item {
                        display: inline-flex;
                        flex-direction: column;
                        width: 563px;
                        cursor: pointer;
                        margin-bottom: 80px;
                
                        // &:nth-child(even) {
                        //     float: right;
                        // }
                
                        &:hover {
                            .description {
                                opacity: 1;
                                max-height: 100vh;
                                transition: all 0.9s ease;
                            }
                        }
                
                        .top {
                            position: relative;
                        }
                
                        .image {
                            width: 563px;
                            height: 574px;
                
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius:6px;
                            }

                        }
                
                        .description {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            color: white;
                            padding: 40px;
                            -webkit-backdrop-filter: blur(10px);
                            backdrop-filter: blur(10px);
                            background-color: rgba(black, 0.8);
                            border-bottom-left-radius: 6px;
                            border-bottom-right-radius: 6px;
                            line-height: 1.5;
                            font-size: 1.25rem;
                            opacity: 0;
                            max-height: 0;
                            transition: all 0.9s ease;
                
                        }
                
                        .head {
                            margin-top: 20px;
                            color: black;
                            display: flex;
                            flex-direction: column;
                            background-color: transparent;

                            .name{
                                background-color: transparent;
                            }
                
                            .text {
                                font-size: 1.25rem;
                            }
                
                        }
                
                    }

                }

                .head{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 30px 0px;
                    p{
                        margin: auto 0px;
                    }
                }

            }

            .image-one{
                width:1210px;
                height:574px
            }
    
            .image-two{
                width: 563px;
                height: 574px;
                border-radius: 6px;

            }

            .name{
                background-color: rgb(34, 39, 39);
            }

            .one-line{
                background-color: aqua;
            }

        }
    }
    
}

@media only screen and (max-width: map-get(theme.$brakpoint, 'xl')) {

    .inner-product{

        .introduction-section {
        
            display: flex;
            width: 100%;
            padding-top: 150px;
            padding-bottom: 100px;
            background-color: #F9F9F9;
        
            .section-container {
                width: 100%;
                height:  350px;
                max-width: map-get(theme.$brakpoint, 'lg');
                display: flex;
                flex-direction: row;
                margin: auto;
        
                .container-content {
                    margin-left: 0px;
                    width: 100%;
        
                    .label {
                        font-family: Outfit;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 130%;
                    }
        
                    .title {
                        font-size: 40px;
                        font-weight: 300;
                        line-height: 130%;
                        color: rgb(90, 83, 107);
        
                        span {
                            background: -webkit-linear-gradient(99.92deg, #67B094 7.85%, #BAD48A 57.45%);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
        
                    .description {
                        font-family: Outfit;
                        font-weight: 300;
                        margin-top: 20px;
                        font-size: 16px;
                        line-height: 130%;
                        color: rgba(0, 0, 0, 0.6);
                    }
        
                    .container-image {
                        display: none;
                    }
        
                }
        
                .container-image {
                    width: 100%;
                    display: flex;
        
                    .image-container {
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: fit-content;
                        margin: auto;
                        right: 0px;

                        .display-image {
                            position: relative;
                            width: 450px ;
                            height:  450px ;
                        }

                    }
        
        
                    
                }

                .botton-container {
                    margin-left: -30px !important;
                }
            }
        
        }

        .product-section {
            padding: 50px 0px;
        
            .section-container{
                margin: auto;
                width: 100%;
                height: 100%;
                display: flex;
                max-width: map-get(theme.$brakpoint, 'lg');
                flex-direction: column;
                position: relative;

    
                .description{
                    font-family: Outfit;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: center;
                    width: 100% ;
                    margin: auto;
                    padding: 40px 0px;
                }
    
                .inner-container{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 100%;
                    height: 1000px;
                    margin: auto;

                    .image-container{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                    }

                }
    
                .image-one{
                    width:100%;
                    height:474px
                }
        
                .image-two{
                    width: 50%;
                    height: 474px;
                    border-radius: 6px;
                    &:nth-child(1){
                        padding-right: 25px;
                    }

                    &:nth-child(2){
                        padding-left: 25px;
                    }
                }

            }
        
        }

        .contact-center-solution{
            padding-top: 100px;
    
            .section-container{
                margin: auto;
                width: 100%;
                height: 100%;
                display: flex;
                max-width: map-get(theme.$brakpoint, 'lg');
                flex-direction: column;
                position: relative;
    
                .card-container {
                    padding-top: 20px;
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    justify-content: center;
    
                    .card {
                        font-family: Outfit;
                        background-color: #F9F9F9;
                        font-weight: 300;
                        font-size: 16px;
                        text-align: left;
                        line-height: 27px;
                        width: 350px;
                        height: auto;
                        color: rgba(58, 49, 76, 0.8);
                        margin-left: 15px;
                        margin-bottom: 30px;
                        margin-top: 30px;
                        border-radius: 6px;
                        padding: 20px;
                        border: none;
                        outline: none;
                        // box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
        
                        .card-icon {
                            margin-left: 10px;
                            position: absolute;
                            margin-top: -55px;
                            width: 70px;
                            height: 70px;
                        }
        
                        .title {
                            font-family: Outfit;
                            font-weight: 600;
                            font-size: 21px;
                            text-align: left;
                            color: rgb(58, 49, 76);
                            padding-top: 20px;
                        }
                    }
                
                }
    
                .title{
                    font-family: Outfit;
                    font-size: 40px;
                    font-weight: 300;
                    line-height: 58px;
                    width: 100%;
                    letter-spacing: 0em;
                    text-align: center;
        
                    span{
                        font-weight: 600;
                    }
        
                }
            }
    
        }
    
        .explore-other-products-section{
            padding: 60px 0px;
            background-color: #F9F9F9;
    
            .section-container{
    
                .inner-container{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    max-width: map-get(theme.$brakpoint, 'lg');
                    height: fit-content;
                    margin: auto;

                    .image-container{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 100%;
                        max-width: map-get(theme.$brakpoint, 'lg');
                        width: calc(100vw - 40px);

                        
                    .item {
                        display: inline-flex;
                        flex-direction: column;
                        width: 486px;
                        cursor: pointer;
                        margin-bottom: 40px;
                
                        // &:nth-child(2) {
                        //     padding-left: 20px;
                        // }
                
                        &:hover {
                            .description {
                                opacity: 1;
                                max-height: 100vh;
                                transition: all 0.9s ease;
                            }
                        }
                
                        .top {
                            position: relative;
                        }
                
                        .image {
                            width: 100%;
                            height: 100%;
                
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius:6px;
                            }

                        }
                
                        .description {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            color: white;
                            padding: 40px;
                            -webkit-backdrop-filter: blur(10px);
                            backdrop-filter: blur(10px);
                            background-color: rgba(black, 0.8);
                            border-bottom-left-radius: 6px;
                            border-bottom-right-radius: 6px;
                            line-height: 1.5;
                            font-size: 1rem;
                            opacity: 0;
                            max-height: 0;
                            transition: all 0.9s ease;
                
                        }
                
                        .head {
                            margin-top: 20px;
                            color: black;
                            display: flex;
                            flex-direction: column;
                            background-color: transparent;

                            .name{
                                background-color: transparent;
                            }
                
                            .text {
                                font-size: 1.25rem;
                            }
                
                        }
                
                    }
                    
                    }
    
                    .head{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        padding: 20px 0px;
                        font-family: Outfit;
                        font-size: 16px;
                        font-weight: 300;
                        line-height: 22px;
                        letter-spacing: 0.16em;
                        text-align: left;

                        p{
                            margin: auto 0px;
                        }
                    }
                }
    
                .image-one{
                    width:100%;
                    height:474px
                }
        
                .image-two{
                    width: 50%;
                    height: 474px;
                    border-radius: 6px;
                    
                    &:nth-child(1){
                        padding-right: 25px;
                    }

                    &:nth-child(2){
                        padding-left: 25px;
                    }
                }
    
                .name{
                    background-color: rgb(34, 39, 39);
                }
                .one-line{
                    background-color: aqua;
                }
            }
        }

    }


}

@media only screen and (max-width: map-get(theme.$brakpoint, 'md')) {

    .inner-product{

        .introduction-section {
    
            display: flex;
            width: 100%;
            padding-top: 100px;
            padding-bottom: 30px;
            padding-left: 20px;
            padding-right: 20px;
            background-color: map.get(theme.$colors, "white");
    
            .section-container {
                width: 100%;
                max-width: map-get(theme.$brakpoint, 'sm');
                height: max-content;
                display: flex;
                flex-direction: row;
                margin: auto;
    
                .container-content {
                    margin-left: 0px;
                    margin-right: 0px;
                    max-width: 600px;
                    width: 100%;

                    .platform{
                        margin-top: 20px;
                        margin-left: -15px;
                        width: calc( 100vw - 20px);
                    }
    
                    .label {
                        font-family: Outfit;
                        font-size: 16px;
                        font-weight: 300;
                        line-height: 20px;
                        letter-spacing: 0.16em;
                        text-align: left;
    
                    }
    
                    .title {
                        font-family: Outfit;
                        font-size: 26px;
                        font-weight: 300;
                        line-height: 34px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: rgb(90, 83, 107);
    
                        span {
                            background: -webkit-linear-gradient(99.92deg, #67B094 7.85%, #BAD48A 57.45%);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
    
                    .description {
                        font-family: Outfit;
                        font-weight: 300;
                        margin-top: 20px;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgba(0, 0, 0, 0.6);
                        text-align: left;
                    }
    
                    .container-image {
                        width: auto;
                        height: fit-content;
                        display: flex;
                        flex-direction: row;

                        .image-container {
                            height: 237px;
                            display: flex;
                            position: relative;

                            .display-image {
                                position: relative;
                                margin: auto;
                                height: 100%;
                                width: auto;
                                transform: scale(1)
                            }
                        }
                    }

                    .bullet-point-container{
                        display: flex;
                        flex-direction: column;
                        .bullet-item{
                            display: flex;
                            flex-direction: row;
                            padding: 5px;
    
                            .icon-container{
                                display: flex;
                                flex-direction: column;
    
                                .buttet-icon{
                                    margin-top: 5px;
                                    width: 15px;
                                    height: 15px;
                                }
                            }
    
                            .bullet-text{
                                margin-left: 8px;
                                font-family: Outfit;
                                font-size: 16px;
                                line-height: 24px;
                                letter-spacing: 0em;
                                text-align: left;
                                color: rgba(0, 0, 0, 0.6);
                            }
                        }
                    }

                }
    
                .container-image {
                    display: none;
                }

            }
    
        }

        .product-section {

            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 0px;

            .section-container{
                margin: auto;
                width: 100%;
                height: 100%;
                display: flex;
                max-width: map-get(theme.$brakpoint, 'sm');
                flex-direction: column;
                position: relative;

                .title {
                    font-size: 30px;
                    font-weight: 300;
                    color: rgb(90, 83, 107);
                    text-align: left;
                    margin-bottom: 0rem;
                    padding-bottom: 0px;
    
                    span {
                        color: rgb(243, 104, 74);
                    }
    
                }
    
                .description{
                    font-family: Outfit;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 27px;
                    max-width: map-get(theme.$brakpoint, 'sm');
                    letter-spacing: 0em;
                    text-align: left;
                    width: 100% ;
                    margin: auto;
                    padding: 20px 0px;
                    color:#4D5460;

                }
    
                .inner-container{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 100%;
                    height: fit-content;
                    margin: auto;


                    .image-container{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }
                }
    
                .image-one{
                    width: 100%;
                    height: 220px;
                    padding-bottom: 20px;
                }
        
                .image-two{
                    width: 100%;
                    height: 280px;
                    border-radius: 6px;

                    &:nth-child(1){

                        padding-right: 0px;
                    }

                    &:nth-child(2){
                        margin-top: 20px;
                        padding-left: 0px;
                    }
                }

                
            }
        
        }


        .contact-center-solution{
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 40px;
                
            .section-container{
                margin: auto;
                width: 100%;
                height: 100%;
                display: flex;
                max-width: map-get(theme.$brakpoint, 'md');
                flex-direction: column;
                position: relative;
    
                .card-container {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    justify-content: center;
    
                    .card {
                        position: relative;
                        font-family: Outfit;
                        font-weight: 300;
                        font-size: 16px;
                        text-align: left;
                        line-height: 27px;
                        width: 100%;
                        height: auto;
                        color: rgba(58, 49, 76, 0.8);
                        margin-bottom: 30px;
                        margin-top: 60px !important;
                        border-radius: 6px;
                        padding: 20px;
                        border: none;
                        outline: none;
                        margin: auto;
        
                        .card-icon {
                            margin-left: 10px;
                            position: absolute;
                            margin-top: -55px;
                            width: 70px;
                            height: 70px;
                            transform: scale(0.8);
                        }
        
                        .title {
                            font-family: Outfit;
                            font-weight: 600;
                            font-size: 18px;
                            text-align: left;
                            color: rgb(58, 49, 76);
                            padding-top: 20px;
                        }
                    }
                
                }
    
                .title{
                    font-family: Outfit;
                    font-size: 30px;
                    font-weight: 300;
                    line-height: 56px;
                    width: 100%;
                    letter-spacing: 0em;
                    text-align: center;
        
                    span{
                        font-weight: 600;
                    }
        
                }
            }
    
        }

        .explore-other-products-section{
            padding: 30px 20px;
            background-color: #F9F9F9;
    
            .section-container{
    
                .inner-container{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 100%;
                    max-width: map-get(theme.$brakpoint, 'sm');
                    height: fit-content;
                    margin: auto;

                    .image-container{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        width: calc(100vw - 40px);
                        width: 100%;
                        
                    .item {
                        display: inline-flex;
                        flex-direction: column;
                        width: 100%;
                        cursor: pointer;
                        margin-bottom: 40px;
                
                        // &:nth-child(even) {
                        //     float: right;
                        // }
                
                        &:hover {
                            .description {
                                opacity: 1;
                                max-height: 100vh;
                                transition: all 0.9s ease;
                            }
                        }
                
                        .top {
                            position: relative;
                        }
                
                        .image {
                            width: 100%;
                            height: 100%;
                
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius:6px;
                            }

                        }
                
                        .description {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            color: white;
                            padding: 40px;
                            font-size: 16px;
                            -webkit-backdrop-filter: blur(10px);
                            backdrop-filter: blur(10px);
                            background-color: rgba(black, 0.8);
                            border-bottom-left-radius: 6px;
                            border-bottom-right-radius: 6px;
                            line-height: 1.5;
                            font-size: 1rem;
                            opacity: 0;
                            max-height: 0;
                            transition: all 0.9s ease;
                            color:#4D5460;
                
                        }
                
                        .head {
                            margin-top: 20px;
                            color: black;
                            display: flex;
                            flex-direction: column;
                            background-color: transparent;

                            .name{
                                background-color: transparent;
                            }
                
                            .text {
                                font-size: 1.25rem;
                            }
                
                        }
                
                    }

                    }
    
                    .head{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 20px 0px;
                        font-family: Outfit;
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 22px;
                        letter-spacing: 0.16em;
                        text-align: left;

                        p{
                            margin: auto 0px;
                        }
                    }
                }
    
                .image-one{
                    width:100%;
                    height:200px
                }
        
                .image-two{
                    width: 100%;
                    height: 200px;
                    border-radius: 6px;

                    &:nth-child(1){
                        padding-right: 10px;
                    }

                    &:nth-child(2){
                        padding-left: 10px;
                    }
                }
    
                .name{
                    background-color: rgb(34, 39, 39);
                }
                .one-line{
                    background-color: aqua;
                }
            }
        }

    }

}