
.get_know_us{
    width:210px;
    height:60px;
    cursor:pointer;
    border-width: 0px;
    border: transparent;
    background-position: center;
    background-size: 210px 60px !important;
    background-repeat: no-repeat !important;

    @include md{
        transform: scale(0.8);
        margin-left: -20px;
    }
}

.button_connect{
    width:155px;
    height:60px;
    cursor:pointer;
    border-width: 0px;
    border: transparent;
    background-position: center;
    background-size: 155px 60px !important;
    background-repeat: no-repeat !important;

    @include md{
        transform: scale(0.8);
        margin-left: -35px;
    }
}

.button_lets_connect{
    width:200px;
    height:60px;
    cursor:pointer;
    border-width: 0px;
    border: transparent;
    background-position: center;
    background-size: 200px 60px !important;
    background-repeat: no-repeat !important;

    @include md{
        transform: scale(0.8);
        margin-left: -20px;
    }
}

.explore_services{
    width:200px;
    height:60px;
    cursor:pointer;
    border-width: 0px;
    border: transparent;
    background-position: center;
    background-size: 200px 60px !important;
    background-repeat: no-repeat !important;

    @include md{
        transform: scale(0.8);
        margin-left: -20px;
    }
}

.explore_product{
    width:230px;
    height:60px;
    cursor:pointer;
    border-width: 0px;
    border: transparent;
    background-position: center;
    background-size: 230px 60px !important;
    background-repeat: no-repeat !important;

    @include md{
        transform: scale(0.8);
        margin-left: -20px;
    }
}

.button_more_products{
    width:200px;
    height:60px;
    cursor:pointer;
    border-width: 0px;
    border: transparent;
    background-position: center;
    background-size: 200px 60px !important;
    background-repeat: no-repeat !important;
    @include md{
        transform: scale(0.8);
        margin-left: -20px;
    }
}

