.contact-us-section {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .form-container {
        display: flex;
        margin-top: 150px;
        padding-bottom: 100px;

        .form-container-row {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .input-error{
                color: #F3684A;
                height: 30px;
                width: 100%;
            }
                                 
            .contact-us-form input {
                width: 100%;
                margin-top: 5px;
                border-style: none;
                border-bottom-style: solid;
                border-bottom-color: #B1B1B1;
                border-width: 1px;
                height: 45px;
              }
              
              .contact-us-form input:focus {
                outline: none;
              }

            .contact-us-form-head{
                width: auto;
                padding-bottom: 40px;

                h2{
                    span{
                        background: -webkit-linear-gradient(99.92deg, #67B094 7.85%, #BAD48A 57.45%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }

            }

            .contact-us-form {
                width: 660px;
                height: 480px;

                .success-message-container{
                        width: 100%;
                        height: 100%;
                        display: flex;


                        div{
                            margin: auto;
                            display: flex;
                            flex-direction: column;
                            justify-content: left;

                            .success-message{

                                font-family: Raleway;
                                font-size: 50px;
                                font-weight: 300;
                                line-height: 65px;
                                letter-spacing: 0em;
                                text-align: center;
                                width: auto;
                                white-space: nowrap;

                                span{
                                    font-family: Raleway;
                                    font-size: 50px;
                                    font-weight: 500;
                                    line-height: 65px;
                                    letter-spacing: 0em;
                                    text-align: center;
                                    color: #F3684A;
                                }

                            }

                            .short-message{
                                font-family: Raleway;
                                font-size: 24px;
                                line-height: 31px;
                                letter-spacing: 0em;
                                text-align: left;

                            }
                        }

                }


              }


              .contact-us-button{
                margin-top: -30px;
                margin-left: 25px;
              }

              .display-container{

                .contact-us-right-display-pic {
                    margin-right: -100px;
                    width: 500px;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    /* clip-path: ellipse(69% 65% at 69% 65%); */
                    clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
                }
              }


          }

      }

}

@media only screen and (max-width: map-get($brakpoint, 'md')) {

.contact-us-section {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .form-container {
        display: flex;
        flex-direction: column;
        margin: auto;
        justify-content: center;


        .container{
            margin-top: 60px;
        }

        .form-container-row {
            display: flex;
            width: 100%;
            height: fit-content;
            justify-content: space-between;

            .contact-us-form-head{
                width: auto;
            }

            .contact-us-form {
                width: auto;
                height: 100%;
                display: flex;
                flex-direction: column;
                    
                    .contact-us-form input {
                    margin-top: 20px;
                    border-style: none;
                    border-bottom-style: solid;
                    border-bottom-color: #B1B1B1;
                    border-width: 1px;
                    height: 45px;
                    }
                    
                    .contact-us-form input:focus {
                    outline: none;
                    }


                .success-message-container{
                        width: 100%;
                        height: 300px;
                        display: flex;

                        div{
                            margin: auto;
                            display: flex;
                            flex-direction: column;
                            justify-content: left;

                            .success-message{

                                font-family: Raleway;
                                font-size: 30px;
                                font-weight: 300;
                                line-height: 130%;
                                letter-spacing: 0em;
                                text-align: left;
                                white-space: wrap;

                                span{
                                    font-family: Raleway;
                                    font-size: 30px;
                                    font-weight: 500;
                                    line-height: 65px;
                                    letter-spacing: 0em;
                                    text-align: center;
                                    color: #F3684A;
                                }

                            }

                            .short-message{
                                font-family: Raleway;
                                font-size: 18px;
                                line-height: 130%;
                                letter-spacing: 0em;
                                text-align: left;

                            }
                        }

                }

                }
            }

            .contact-us-button{
            margin-top: 30px;
                margin-left: 25px;
            }

           
            .display-container{

                display: none;

                .contact-us-right-display-pic {
                    margin-right: -100px;
                    width: 500px;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    /* clip-path: ellipse(69% 65% at 69% 65%); */
                    clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
                }

              }

        }

    }
}