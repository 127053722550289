.footer-section{
    width: 100vw;
    height: 550px;
    display: flex;
    position: relative;
    bottom: 0;
    background-color: #0B244D;

    .section-container{
        display: flex;
        height: 100%;
        width: 100%;
        margin: auto;
        padding-top: 100px;
        padding-bottom: 20px;
        background-color: #0B244D;

        .inner-container{

            display: flex;
            max-width: 1210px;
            height: 100%;
            width: 100%;
            flex-direction: column;
            margin: auto;

            .footer-top{

                display: flex;
                flex-direction: row;
                display: flex;
                height: 100%;
                width: 100%;
                margin-bottom: auto;

                .logo-container{
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    width: 400px;


                    .footer-logo {
                        margin-bottom: 80px;
                        width: 70px;
                        height: 70px;
                    }

                    .footer-title {

                        font-family: Raleway;
                        font-size: 40px;
                        line-height: 48px;
                        letter-spacing: 0em;
                        font-weight: normal;
                        text-align: left;
                        color: white;
                        white-space: nowrap;
                        // background-color: aquamarine;

                        @include md {
                            font-size: 1.5rem;

                            br {
                                display: none;
                            }
                        }
                    }

                }

                .link-container{
                    display: flex;
                    flex-direction: row;
                    height: 100%;
                    width: fit-content;
                    margin-left: auto;

                    div{
                        width: fit-content;
                        display: flex;
                        flex-direction: row;
                        height: 100%;
                        margin-left: 2px;
                        margin-right: 2px;

                        .footer-list {
                            display: inline-flex;
                            flex-direction: column;
                            list-style: none;
                            margin-bottom: 0;
                            padding-left: 80px;

                            &:first-child {
                                padding-left: 0;
                            }

                            .head {
                                font-size: 16px;
                                font-weight: 600;
                                margin-bottom: 30px;
                                text-transform: uppercase;
                                color: white;
                            }

                            li {
                                margin-bottom: 18px;

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                a {
                                    color: rgba(255, 255, 255, 0.68);
                                    cursor: pointer;
                                    text-decoration: none;
                                    font-size: 16px;

                                    &:hover {
                                        color: #BAD48A;
                                    }
                                }
                            }

                        }

                    }




                }

            }

            .footer-bottom{
                display: flex;
                flex-direction: column;
                margin-top: auto;
                height: 100px;
                width: 100%;
                margin-bottom: 10px;

                .socialmedia-container{
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    height: fit-content;
                
                    .footer-social {
                        align-items: center;
                        display: flex;
                        float: right;
                        gap: 40px;
                        width: fit-content;
                        list-style: none;
                        margin-left: auto;
                        padding: 0;
                        
                
                        a{

                            img{
                                width: 20px;
                                height: 20px;
                            }

                            .active{
                                display: none;
                            }
                            .default{
                                display: block;
                            }
                        }
                
                        a:hover{
                            .active{
                                display: block;
                            }
                            .default{
                                display: none;
                            }
                        }
                

                    }

                }

                .footer-bar {
                    background-color: white;
                    width: 100%;
                    position: relative;
                    opacity: 1;
                    height: 2px;

                    &::before {
                        content: '';
                        background-color: map-get($colors, 'white');
                        height: 13px;
                        width: 100px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: inline-block;
                    }
                }

                .copyright-container {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                    justify-content: space-between;
                    list-style: none;
                    margin: 0;
                    height: 25px;
                    margin-top: 30px;
                    color: white;

                    div{
                        height: fit-content;
                        margin-top: auto;
                        margin-bottom: auto;
                        height: 25px;
                        font-size: 16px;
                        line-height: 18px;
                        letter-spacing: 0em;
                        text-align: left;
                    }

                    ul{
                        display: flex;
                        flex-direction: row;
                        list-style-type: none;
                        height: fit-content;
                    
                        li {
                            border-right: 1px map-get($borders, 'solid');
                            padding-right: 20px;
                            padding-left: 20px;

                            &:last-child {
                                border: none;
                                padding-right: 0;
                            }
    
                            a {
                                color: map-get($colors, 'white');
                                cursor: pointer;
                                text-decoration: none;
    
                                &:hover {
                                    color: #BAD48A;
                                }
                            }
    
                        }
                    }

                }

            }

        }
    }
    
}

@media only screen and (max-width: map-get($brakpoint, 'xl')) {

    .placeholder {
        width: 100vw;
        visibility: hidden;
        display: none;
        height: 0px;
        position: relative;
        background: transparent;
      }

    .footer-section{
        width: 100%;
        height: 550px;
        display: flex;
        position: relative;
        bottom: 0;

    
        .section-container{
            display: flex;
            max-width: map-get($brakpoint, 'lg');;
            height: 100%;
            width: 100%;

            margin: auto;
            padding-top: 100px;
            padding-bottom: 20px;
    
            .inner-container{
                display: flex;
                max-width: 1210px;
                height: 100%;
                width: 100%;
                flex-direction: column;
                margin: auto;
    
                .footer-top{
                    display: flex;
                    flex-direction: row;
                    display: flex;
                    height: 100%;
                    width: 100%;
                    margin-bottom: auto;
    
                    .logo-container{
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        width: 400px;
    
    
                        .footer-logo {
                            margin-bottom: 80px;
                            width: 70px;
                            height: 70px;
                        }
    
                        .footer-title {
                            font-size: 30px;
                            line-height: 46px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: white;
                            white-space: nowrap;
    
                        }
    
                    }
    
                    .link-container{
                        display: flex;
                        flex-direction: row;
                        height: 100%;
                        width: fit-content;
                        margin-left: auto;
    
                        div{
                            width: fit-content;
                            display: flex;
                            flex-direction: row;
                            height: 100%;
                            margin-left: 2px;
                            margin-right: 2px;
    
                            .footer-list {
                                display: inline-flex;
                                flex-direction: column;
                                list-style: none;
                                margin-bottom: 0;
                                padding-left: 80px;
    
                                &:first-child {
                                    padding-left: 0;
                                }
    
                                .head {
                                    font-size: 14px;
                                    font-weight: 600;
                                    margin-bottom: 30px;
                                    text-transform: uppercase;
                                    color: white;
                                }
    
                                li {
                                    margin-bottom: 18px;
    
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
    
                                    a {
                                        color: map-get($colors, 'white');
                                        cursor: pointer;
                                        text-decoration: none;
                                        font-size: 14px;
    
                                        &:hover {
                                            color: $primary;
                                        }
                                    }
                                }

                            }
    
                        }
    
    
    
    
                    }
                }
    
                .footer-bottom{
                    display: flex;
                    flex-direction: column;
                    margin-top: auto;
                    height: 100px;
                    width: 100%;
                    margin-bottom: 10px;
    
                    .socialmedia-container{
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        height: fit-content;
                    
                        .footer-social {
                            align-items: center;
                            display: flex;
                            float: right;
                            gap: 40px;
                            width: fit-content;
                            list-style: none;
                            margin-left: auto;
                            padding: 0;

                     
                    
                            a{

                                img{
                                    width: 15px;
                                    height: 15px;
                                }

                                .active{
                                    display: none;
                                }
                                .default{
                                    display: block;
                                }
                            }
                    
                            a:hover{
                                .active{
                                    display: block;
                                }
                                .default{
                                    display: none;
                                }
                            }
                    
                        }
                    }
    
                    .footer-bar {
                        background-color: white;
                        width: 100%;
                        position: relative;
                        opacity: 1;
                        height: 2px;
    
                        &::before {
                            content: '';
                            background-color: map-get($colors, 'white');
                            height: 13px;
                            width: 100px;
                            position: absolute;
                            left: 0;
                            top: 0;
                            display: inline-block;
                        }
                    }
    
                    .copyright-container {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        justify-content: space-between;
                        list-style: none;
                        margin: 0;
                        height: 25px;
                        margin-top: 25px;
                        color: white;
    
                        div{
                            height: fit-content;
                            margin-top: auto;
                            margin-bottom: auto;
                            height: 25px;
                            font-size: 14px;
                            line-height: 16px;
                            letter-spacing: 0em;
                            text-align: left;
                        }
    
                        ul{
                            display: flex;
                            flex-direction: row;
                            list-style-type: none;
                            height: fit-content;
                        
                            li {
                                border-right: 1px map-get($borders, 'solid');
                                padding-right: 20px;
                                padding-left: 20px;

                                &:last-child {
                                    border: none;
                                    padding-right: 0;
                                }
        
                                a {
                                    color: map-get($colors, 'white');
                                    cursor: pointer;
                                    text-decoration: none;
        
                                    &:hover {
                                        color: $primary;
                                    }
                                }
        
                            }
                        }
    
                    }
    
                }
    
            }
        }

    }

}

@media only screen and (max-width: map-get($brakpoint, 'md')){


    .placeholder {
        width: 100vw;
        visibility: hidden;
        display: none;
        height: 0px;
        position: relative;
        background: transparent;
    }

    .footer-section{
        width: 100vw;
        height: fit-content;
        display: flex;
        position: relative;
        bottom: 0;
        overflow: auto;
        padding: 0px 20px;
    
        .section-container{
            display: flex;
            max-width: auto;
            height: 100%;
            width: 100%;
            margin: auto;
            padding-top: 80px;
            padding-bottom: 20px;
    
            .inner-container{
                display: flex;
                max-width: 1210px;
                height: fit-content;
                width: 100%;
                flex-direction: column;
                margin: auto;
    
                .footer-top{
                    display: flex;
                    flex-direction: column;
                    display: flex;
                    height: 100%;
                    width: 100%;
                    margin-bottom: auto;
                    padding-bottom: 60px;
    
                    .logo-container{
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        width: 100%;
    
    
                        .footer-logo {
                            margin-bottom: 40px;
                            width: 70px;
                            height: 70px;
                        }
    
                        .footer-title {
                            font-size: 24px;
                            line-height: 30px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: white;
                            white-space: pre-wrap;
                        }
    
                    }
    
                    .link-container{
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        width: fit-content;
                        margin-left: 0;
    
                        div{
                            width: fit-content;
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            margin-left: 2px;
                            margin-right: 2px;
    
                            .footer-list {
                                display: inline-flex;
                                flex-direction: column;
                                list-style: none;
                                margin-bottom: 0;
                                padding-left: 0px;
    
                                &:first-child {
                                    padding-left: 0;
                                }
    
                                .head {
                                    font-size: 14px;
                                    font-weight: 600;
                                    margin-bottom: 30px;
                                    padding-top: 60px;
                                    text-transform: uppercase;
                                    color: white;
                                }
    
                                li {
                                    margin-bottom: 12px;
    
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
    
                                    a {
                                        color: map-get($colors, 'white');
                                        cursor: pointer;
                                        text-decoration: none;
                                        font-size: 14px;
    
                                        &:hover {
                                            color: $primary;
                                        }
                                    }
                                }

                            }
    
                        }
    
    
    
    
                    }
                }
    
                .footer-bottom{
                    display: flex;
                    flex-direction: column;
                    margin-top: auto;
                    height: 150px;
                    width: 100%;
                    margin-bottom: 10px;
    
                    .socialmedia-container{
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        height: fit-content;
                    
                        .footer-social {
                            align-items: center;
                            display: flex;
                            float: right;
                            gap: 40px;
                            width: fit-content;
                            list-style: none;
                            margin-left: auto;
                            padding: 0;
                    
                            a{

                                img{
                                    width: 15px;
                                    height: 15px;
                                }

                                .active{
                                    display: none;
                                }
                                .default{
                                    display: block;
                                }
                            }
                    
                            a:hover{
                                .active{
                                    display: block;
                                }
                                .default{
                                    display: none;
                                }
                            }
                    
                        }
                    }
    
                    .footer-bar {
                        background-color: white;
                        width: 100%;
                        position: relative;
                        opacity: 1;
                        height: 2px;
    
                        &::before {
                            content: '';
                            background-color: map-get($colors, 'white');
                            height: 13px;
                            width: 100px;
                            position: absolute;
                            left: 0;
                            top: 0;
                            display: inline-block;
                        }
                    }
    
                    .copyright-container {
                        display: flex;
                        flex-direction: column-reverse;
                        gap: 20px;
                        list-style: none;
                        margin: 0;
                        height: 25px;
                        margin-top: 70px;
                        color: white;
    
                        div{
                            display: flex;
                            margin-top: auto;
                            margin-bottom: auto;
                            height: 25px;
                            font-size: 14px;
                            line-height: 16px;
                            letter-spacing: 0em;
                            text-align: center;
                        }

                        .copyright{
                            text-align: center;
                            margin: auto;
                        }
    
                        ul{
                            display: flex;
                            flex-direction: row;
                            list-style-type: none;
                            height: fit-content;
                            margin: auto;
                        
                            li {
                                border-right: 1px map-get($borders, 'solid');
                                padding-right: 20px;
                                padding-left: 20px;

                                &:last-child {
                                    border: none;
                                    padding-right: 0;
                                }
        
                                a {
                                    color: map-get($colors, 'white');
                                    cursor: pointer;
                                    text-decoration: none;
        
                                    &:hover {
                                        color: $primary;
                                    }
                                }
        
                            }
                        }
    
                    }
    
                }
    
            }
        }
    }

}