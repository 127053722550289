.main-section-display{
    
    // background: url(../../images/banner-00.png);
    backdrop-filter: brightness(80%);
    display: flex;
    align-items: center;
    height: 90vh;

    div{
        margin: auto;
        width: fit-content;
        justify-content: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .button-container{
          display: flex;
          flex-direction: row;
          margin: auto;
          transform: translateX(20px);
          margin-top: 20px;
        }
        
      }
    
    .section-title{
        margin: auto;
        font-family: Raleway;
        font-style: normal;
        font-weight: 300;
        font-size: 50px;
        line-height: 130%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;

        .case-study{
          font-family: "Raleway";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 22.14px;
          transform: translateY(40px);
       
        }
      }

    .sub-title{
        height: 62px;
        padding-top: 25px;
        font-family: Outfit;
        font-size: 24px;
        font-weight: 100;
        line-height: 130%;
        max-width: 438px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #EEEEEE;
        margin: auto;

      }

}

@media only screen and (max-width: map-get($brakpoint, 'xl')) {
  .main-section-display{
    backdrop-filter: brightness(80%);
    display: flex;
    align-items: center;
    min-height: 100vh;

    div{
        margin: auto;
        width: fit-content;
        justify-content: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .button-container{
          display: flex;
          flex-direction: row;
          margin: auto;
          transform: translateX(20px);
          margin-top: 20px;
        }
      }
    
    .section-title{
        margin: auto;
        font-family: Outfit;
        font-style: normal;
        font-weight: 300;
        font-size: 50px;
        line-height: 130%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;

        .case-study{
          font-family: "Raleway";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22.14px;
          transform: translateY(40px);
        }
      }

    .sub-title{
        height: 60px;
        margin-top: 14px;
        font-family: Outfit;
        font-size: 22px;
        line-height: 130%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #EEEEEE;
      }

}
}

@media only screen and (max-width: map-get($brakpoint, 'md')) {
  
  .main-section-display{
  
    background: url(../../images/banner-00.png);
    backdrop-filter: brightness(80%);
    display: flex;
    align-items: center;
    min-height: 90vh;

    .blog-inner-page-section{
      width: 100vw;
      padding: 0px 20px;
    }

    div{
      
        margin: auto;
        width: fit-content;
        justify-content: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .button-container{
          display: flex !important;
          flex-direction: row !important;
        }

      }
    
      .section-title{
        padding: 20px;
        font-family: Outfit;
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 130%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
      }

      .sub-title{
        font-family: Outfit;
        font-size: 16px;
        line-height: 130%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #EEEEEE;
      }



  }
}