/* ------------------
- Home Page 
---------------------*/

// - Main Banner ------------------
.main-banner { 



    @extend .section-align;
    // background: url(../../background-images/background_01.png) no-repeat center center / cover;
    background-color: black;
    color: map-get($colors, 'white');
    height: 100vh;
    width: 100vw;


    .title {
        font-family: Outfit;
        position: absolute;
        left: 0px;
        top: 0px;
        font-size: 64px;
        font-weight: 600;
        height: 100vh;
        width: 100%;
        text-align: left;
        padding-left: 200px;
        transform: translateY(25vh);
        z-index: 1000;
        pointer-events: none;
        overflow: hidden;
        // transition: all 0.5s ease 0s;

        // @include lg {
        //     font-size: 2.5rem;
        //     padding-left: 100px;
        //     transform: translateY(30vh);
        // }

        @include md {
            max-width: 100%;
            font-size: 34px;
            padding-left: 30px;
            padding-right: auto;
            transform: translateY(40vh);
        }
    }

    @include md {
        height: 100vh;
    }
}


// - service-offerings ------------------
.service-offerings {
    @extend .section-align;
    background: url(../../background-images/background_00.png) no-repeat center center / cover;
    color: map-get($colors, 'white');
    padding: 100px 0px;

    @include md{
        padding: 0px 0px;
    }


    .container-title{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        @include md{
            display: flex;
            flex-direction: column;
            padding-bottom: 40px;
        }

        .title{

            font-family: Outfit;
            font-size: 50px;
            font-weight: 300;
            line-height: 65px;
            letter-spacing: 0em;
            text-align: left;
            max-width: 460px;

            @include md{

                font-size: 32px;
                font-weight: 300;
                line-height: 36px;
                letter-spacing: 0em;
                text-align: left;
                max-width: 100%;
                padding-bottom: 30px;

            }


            span{

                font-family: Outfit;
                font-size: 50px;
                font-weight: 400;
                line-height: 34px;
                letter-spacing: 0em;
                text-align: left;


            @include md{

                font-size: 32px;
                font-weight: 300;
                line-height: 36px;
                letter-spacing: 0em;
                text-align: left;
                max-width: 100%;

            }

            }

           

        }

        img{
            margin-top: auto;

            @include md{
                margin-top: 30px;
                margin-bottom: 30px;
            }
        }


    }

    .container-content {
        display: flex;
        flex-flow: wrap;
        width: 100%;
        margin: 75px auto auto;
        justify-content: center;

        .content-item {
            font-family: Outfit;
            font-size: 18px;
            font-weight: 300;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: left;

            width: 356px;
            height: 280px;
            color: rgba(255, 255, 255, 0.6);
            margin-right: 20px;
            margin-bottom: 30px;
            margin-top: 30px;
            background-color: #0B244D;
            border-radius: 6px;
            padding: 20px;
            box-shadow: '0px 4px 19px 0px rgba(0, 0, 0, 0.03)';

            .title {
                font-family: Outfit;
                font-size: 20px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: 0em;
                text-align: left;
                text-transform: uppercase;
                padding-top: 27px;
                color: white;

                @include md{
                    font-size: 18px;
                }

                // font-family: Raleway; font-weight: 600; font-size: 20px; text-align: left; color: white;;
            }

            @include md{
                font-size: 16px;
                font-weight: 100;
                line-height: 21px;
                margin-right: 0px;
                padding: 30px;
                width: 100%;
                height: fit-content;
            }


        }
        
        .item-icon {
            margin-left: 15px;
            position: absolute;
            margin-top: -60px;
            width: 70px;
            height: 70px;

            @include md{
                margin-top: -70px;
                margin-left: 0px;
            }
        }

        @include md{
            margin: 0px auto auto;
        }

    }

    .section-text {
        max-width: 70%;

        @include md {
            max-width: 100%;
        }
    }

    .mobile-display{
        padding: 64px 0px;
    }

    .fiveG-image {
        position: relative;
        margin: auto;

        @include md {
            right: 0;
            max-width: 100%;
        }
    }
}

// - WHO ARE WE  ------------------
.whoAreWe-section {
    // @extend .section-align;
    padding-top: 150px;
    padding-bottom: 50px;
    height: fit-content;

    .container{
        display: flex;
        flex-direction: row;
        
        .image-container{
            width: 50%;
            
            .whoAreWe-image {
                float: left;
        
                @include md {
                    max-width: 100%;
                    margin-top: 20px;
                }
            }

            @include lg{
                display: none;
            }

        }

        .content-container{
            width: 50%;
            .section-title {
                font-family: Outfit;
                font-size: 50px;
                line-height: 130%;
                font-weight: 400;

                b {
                    font-weight: 500;
                }
            
            
                @include lg {
                    font-size: 2.125rem;
                    padding-bottom: 10px;
                }
            
                @include md {
                    font-size: 28px;
                }
            }
            
            .section-text {
                font-size: 1.25rem;
                line-height: 1.7;

                @include lg {
                    padding-bottom: 25px;
                }
            
                @include md {
                    font-size: 18px;
                }
            
            }

            @include lg{
                width: 100%;

            }
    
            @include md{
                width: 100%;
            }

        }

        @include lg{
            width: 100%;
            flex-direction: column;
        }

        @include md{
            flex-direction: column;
        }
    }


    .explore-button {

        &::after {
            background-color: $primary;
        }
    }



    .iso-icons {
        align-items: center;
        display: flex;
        gap: 40px;
        list-style: none;
        margin: 0;
        margin-top: 50px;
        padding: 0;

        @include md {
            justify-content: center;
        }
    }

    .more-links {
        align-items: center;
        display: flex;
        gap: 80px;
        justify-content: flex-end;
        list-style: none;
        margin-bottom: 0;
        padding: 0;
        padding-right: 50px;

        @include md {
            flex-direction: column;
            gap: 10px;
            text-align: left;
            margin-top: 20px;
        }
    }

    @include md {
        padding: 0px;
    }


}

// - TEAM Count ------------------
.team-count {

   margin-bottom: 60px;

    .container{
        margin: auto;
        background-color: $bg-five;
        @include md {
            margin: 0px 0px;
            padding: 0px 0px !important;
        }
    }

    .count-list {
        align-items: center;
        display: flex;
        justify-content: space-between;
        list-style: none;
        padding-bottom: 25px;

        li{
            text-align: center;

            // @include md {
            //    margin-left: 20px;
            //    margin-right: 20px;
            // }

            .value {
                color: $tertiary;
                display: block;
                font-size: 4rem;
                font-weight: 600;
                background: -webkit-linear-gradient(99.92deg, #67B094 7.85%, #BAD48A 57.45%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .label {
                font-family: Outfit;
                font-size: 20px;
                font-weight: 300;
                line-height: 25px;
                letter-spacing: 0em;
                text-align: center;
                display: block;
                max-width: 200px;
            }
        }

        @include md {
            background-color: inherit;
            flex-direction: column;
            padding: 0px 20px;

            li {
                display: flex;
                flex-direction: column;
                background-color: $bg-five;
                text-align: center;
                gap: 20px;
                align-items: center;
                width: 100%;
                margin: 20px;

                .label {
                    flex: 0.7;
                    max-width: auto;
                }

                .value {
                    flex: 0.3;
                    font-size: 2.5rem;
                    text-align: right;
                }
            }
        }
    }

    @include md {
        background-color: inherit;
    }

}

// - Our Clients ------------------
.our-clients {

    padding: 0px 20px;

    .container{
        padding: 40px;
        @include md {
            padding-top: 0px;
            padding-bottom: 0px;
            padding: 0px;
        }
    }

    .head {
        text-transform: uppercase;
        font-family: Outfit;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;


    }

    .wave-client {
        display: flex;
        width: 100%;

        .wave-animation {
            width: 50%;

            .sound-wave {
                height: 35px;
                opacity: 0.5;
                width: 100%;

                .bar {
                    animation-name: soundClient;
                    background-color: $text-three;
                    height: 35px;
                }
            }
        }
    }

    .clients-list {

        display: flex;
        justify-content: space-between;
        list-style: none;
        margin-bottom: 50px;
        margin-top: 70px;
        padding: 0;
        overflow-x: auto;

        @include md {
            margin: 20px auto;
            flex-direction: row;

            gap: 10px;

            li {
                margin: auto;

                img {
                    width: 100px;
                    max-width: 100%;
                }
            }
        }

    }

    @include md {
        padding: 50px 15px;
    }
}

// - Our Product ------------------
.our-product-home {
    @extend .section-align;
    border-top: 1px map-get($borders, 'solid') $border-color;
    padding-bottom: 150px;

    .button-container{
        display: flex;
        position: relative;
        flex-direction: row;
        padding-bottom: 60px;
        justify-content: space-between;

        div{
            padding-top: auto;
            padding-bottom: auto;
        }

        .sub-title{
            margin-left: 0px;
            font-family: Raleway;
            font-size: 50px;
            font-weight: 600;
            line-height: 55px;
            letter-spacing: 0em;
            text-align: left;

            @include md {
                padding-bottom: 20px;
                font-size: 30px;
            }
        }

        @include md {
            flex-direction: column;
        }

    }

    .explore{
        padding-top: 90px;
        font-family: Raleway;
        font-size: 50px;
        font-weight: 300;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: left;

        @include md {
            padding-top: 0px;
            font-size: 30px;
        }
    }



    @include md {
        padding: 0px 0px;
    }


}

.product-list {
    display: flex;
    gap: 20px;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        cursor: pointer;
        height: calc(100vh - 200px);
        position: relative;
        transition: all 1s ease;
        width: 25%;

        * {
            transition: all 1s ease;
        }

        &.active {
            transition: all 1s ease;
            width: 50%;
            
            .content {
                padding-top: 60px;
                position: absolute;
                -webkit-backdrop-filter: blur(0px);
                backdrop-filter: blur(0px);
                background-color: transparent;

                .name{
                    padding-top: 20px;
                }

                @include md {
                    margin-top: -40px;
                    padding-left: 20px;
                    padding-top: 0px;
                    position: absolute;
                    height: 40px;
                    -webkit-backdrop-filter: blur(10px);
                    backdrop-filter: blur(10px);
                    background-color: rgba($bg-three, 0.8);
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;

                    .name {
                        margin-top: -10px;
                        color: rgb(255, 255, 255);
                    }
    
                    .text {
                        height: 0;
                        opacity: 0;
                    }
                }
            }

            .image {
                height: calc(100% - 170px);

                @include md {
                    height: calc(100% - 200px);
                }

                img {
                    box-shadow: 0 4px 25px rgba(map-get($colors, 'black'), 0.2);
                }
            }



        }

        &:not(.active) {

            .content {
                position: absolute;

                .name {
                    margin-left: 10px;
                    color: rgb(255, 255, 255);
                }

                .text {
                    height: 0;
                    opacity: 0;
                }
            }
        }
    }

    .image {
        height: 100%;

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    .content {
        margin-top: -40px;
        padding-left: 20px;
        position: absolute;
        height: 40px;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba($bg-three, 0.8);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        .name {
            padding-top: 10px;
            font-weight: 700;
            text-transform: uppercase;
            color: black;
        }

        .text {
            font-size: 1.25rem;
            height: auto;
            margin: 0;
            opacity: 1;
            overflow: hidden;
            color: black;

            @include md {
                padding-bottom: 30px;
            }

        }

        @include md {
            padding-left: 10px;
        }

    }

    @include md {
        flex-direction: column;


        li {
            width: 100%;
            height: 150px;

            &.active {
                width: 100%;
                height: auto;
            }
        }
    }

}


// - Let's Connect ------------------
.let-connect {
    background: #0B1F40 url(../../images/map.png) no-repeat center center;
    // background-color: bisque;
    height: 650px;
    padding: 100px 0 100px;
    text-align: center;

    .title{

        display: none;
        visibility: hidden;

        @include md{
            display: flex;
            visibility: visible;
            color: white;
            text-align: left;
            font-size: 30px;
            font-weight: 600;
            padding-bottom: 20px;
        }

    }


    .more-button {
        font-size: 1.5rem;

        &:hover {
            .star-icon {
                left: 8px;
                transition: all 0.5s ease-in;
            }
        }

        .star-icon {
            left: 0;
            transition: all 0.5s ease-in;
        }
    }

    .map-points {
        display: flex;
        flex-direction: column;
        height: 236px;
        margin: 0 auto 100px;
        max-width: 342px;
        position: relative;

        .point {
            align-items: center;
            background-color: $bg-eight;
            border: 2px map-get($borders, 'solid') $border-color;
            border-radius: 50%;
            cursor: pointer;
            display: inline-flex;
            height: 40px;
            justify-content: center;
            position: absolute;
            transition: all 0.3s ease-in;
            width: 40px;
            // , &.active 
            
            &:hover, &.active {
                background: -webkit-linear-gradient(99.92deg, #67B094 7.85%, #BAD48A 57.45%);
                border-color: #67B094;
                transition: all 0.3s ease-in;

                &::after {
                    opacity: 1;
                    transition: all 0.5s ease;
                }

                .address-col {
                    max-height: 100vh;
                    opacity: 1;
                    padding: 35px;
                    transition: all 0.5s ease;
                }

                @include md {
                    background-color: transparent;
                    background: none;
                    .address-col {
                        padding: 15px;
                    }
                }
            }

            &::before {
                // background: -webkit-linear-gradient(99.92deg, #67B094 7.85%, #BAD48A 57.45%);
                background-color: $border-color;
                border-radius: 50%;
                content: '';
                height: 5px;
                width: 5px;
            }

            &::after {
                background: -webkit-linear-gradient(99.92deg, #67B094 7.85%, #BAD48A 57.45%);
                content: '';
                height: 3px;
                opacity: 0;
                position: absolute;
                transition: all 0.5s ease;
                width: 150px;
            }

            &:first-child {
                left: 101px;
                top: 98px;
    
                &::after {
                    right: 26px;
                    top: 57px;
                    transform: rotate(-27deg);
                }

                .address-col {
                    right: 165px;
                    top: 90px;
                }
            }

            // &:nth-child(2) {
            //     left: 278px;
            //     top: 215px;

            //     &::after {
            //         left: 3px;
            //         top: -44px;
            //         transform: rotate(137deg);
            //     }

            //     .address-col {
            //         bottom: 59px;
            //         left: 140px;
            //     }
            // }

            &:nth-child(2) {
                left: 258px;
                top: 198px;

                &::after {
                    left: 12px;
                    top: -48px;
                    transform: rotate(137deg);
                }

                .address-col {
                    bottom: 59px;
                    left: 140px;
                }
            }


            &:last-child {
                bottom: -60px;
                right: -52px;
            

                &::after {
                    left: 25px;
                    top: 50px;
                    transform: rotate(35deg);
                    width: 84px;
                }

                .address-col {
                    left: 100px;
                    top: -230px;
                    
                    .address{
                        padding-bottom: 20px;
                        padding-top: 10px;
                    }
                }
            }

            @include md {
                background: none;
                border: none;
                border-radius: 0;
                height: auto;
                position: initial;
                max-width: 100%;
                width: 100%;;

                &::before,
                &::after {
                    display: none;
                }
            }
        }

        .address-col {
            background-color: rgba($bg-one, 0.5);
            // background: rgba($bg-one, 0.5);
            border: 3px solid transparent;
            border-image-slice: 1;
            background: linear-gradient(#0B1F40,#0B1F40) padding-box,
            linear-gradient(to right, #67B094,#BAD48A) border-box;
            border-radius: 8px;
            border: 2px solid transparent;
            color: map-get($colors, 'white');
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            padding: 0;
            pointer-events: none;
            position: absolute;
            text-align: left;
            transition: all 0.5s ease-in;

            .name {
                display: block;
                font-size: 1.5rem;
                margin-bottom: 15px;
                white-space: nowrap;

                @include md {
                    font-size: 1.25rem;
                    margin-bottom: 5px;
                }
            }

            .head-office{
                font-family: Raleway;
                color: white;
                font-size: 16px;
                font-weight: bold;
                padding-top: 20px;
                padding-bottom: 10px;
            }

            .address {
                display: block;
                font-size: 1.125rem;
                min-width: 250px;

                @include md {
                    font-size: 1rem;
                }
            }

            @include md {
                position: initial;
                opacity: 1;
                max-height: none;
                padding: 15px;
                margin: 10px auto;
                border-width: 1px;
                width: 100%;
            }
        }

        @include md {
            height: auto;
            padding-bottom: 30px;
            margin: 0 auto;
            max-width: 100%;
            width: auto
        }

    }

    .letconnect-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;



        @include md {
            display: flex;
            flex-direction: row;
            justify-content: center;
            .botton-container{
                margin-left: 0px!important;
            }
            
        }
    }

    @include md {
        display: flex;
        visibility: visible;
        height: auto;
        clear: both;
        background-size: cover;
        padding: 25px 0;
    }

}


.rsis-container + div{
    left: 200px !important;
    bottom: 100px !important;

    @include lg{
        left: 130px !important;
        bottom: 100px !important;
    }

    @include md{
        left: 60px !important;
        bottom: 100px !important;
    }
}

.rsis-image{
    background-position: center;
}

.rsis-image+div{
    background-position: center;
}