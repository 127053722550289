/* ------------------
- WHO ARE WE Page 
---------------------*/

// - Who Are We Main ------------------

    .whoAreWe-main {
        padding-top: 110px;
        padding-bottom: 88px;

        .section-title {
            font-family: Raleway;
            font-size: 50px;
            line-height: 130%;
            font-weight: 300;
            width: 600px;
            padding-bottom: 10px;

            b {
               font-family: Outfit;
                font-weight: 500;
            }
        
        
            @include lg {
                font-size: 2.125rem;
                padding-bottom: 10px;
            }
        
            @include md {
                font-size: 28px;
            }
        }

        .section-text{
            font-family: Outfit;
            font-size: 20px;
            font-weight: 300;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;
            width:445px;
        }


        .rotating {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 50px;

            @include md {
                display: none;
            }
        }

        .rotating-circle {
            position: relative;
            height: 300px;
            width: 470px;
            // border-width: 40px;
            // border-style: map-get($borders, 'solid');
            // border-radius: 50%;
            // border-bottom-color: rgba(map-get($colors, 'black'), 0.2);
            // border-right-color: rgba(map-get($colors, 'black'), 0.2);
            // border-top-color: rgba(map-get($colors, 'black'), 0);
            // border-left-color: rgba(map-get($colors, 'black'), 0);
            // animation: rotate-animation 3s linear 0s infinite;

            // &::before,
            // &::after {
            //     content: '';
            //     display: inline-block;
            //     border-width: 40px;
            //     border-style: map-get($borders, 'solid');
            //     border-radius: 50%;
            //     position: absolute;
            // }

            // &::before {
            //     width: 350px;
            //     height: 350px;
            //     border-bottom-color: rgba(map-get($colors, 'black'), 0.2);
            //     border-right-color: rgba(map-get($colors, 'black'), 0.2);
            //     border-top-color: rgba(map-get($colors, 'black'), 0);
            //     border-left-color: rgba(map-get($colors, 'black'), 0);
            //     top: 20px;
            //     left: 20px;
            //     // animation: rotate-animation 2s linear 0s infinite;
            // }

            // &::after {
            //     width: 230px;
            //     height: 230px;
            //     border-bottom-color: rgba(map-get($colors, 'black'), 0.2);
            //     border-right-color: rgba(map-get($colors, 'black'), 0.2);
            //     border-top-color: rgba(map-get($colors, 'black'), 0);
            //     border-left-color: rgba(map-get($colors, 'black'), 0);
            //     top: 80px;
            //     left: 80px;
            //     transform: translate(-50%, -50%);
            //     animation: anti-rotate-animation 1s linear 0s infinite;
            // }
        }

        @include md {
            padding-top: 75px;
            padding-bottom: 25px;
        }
    }

    .section-box {
        position: relative;

        &:hover {
            .content {
                transform: translate(-10px, 10px);
                transition: transform 0.5s ease;
            }

            .hover {
                opacity: 1;
                transform: translate(10px, -10px);
                transition: transform 0.5s ease;
            }
        }

        .content {
            position: relative;
            transform: translate(0, 0);
            transition: transform 0.5s ease;

            img {
                width: 100%;
                height: 100%;
                border-radius: 6px;
            }

            .head {
                position: absolute;
                font-size: 2.5rem;
                font-weight: 600;
                color: map-get($colors, 'white');
                left: 0;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                text-transform: uppercase;
            }
        }

        .hover {
            opacity: 0;
            border-radius: 6px;
            background-color: #0B1F40;
            color: map-get($colors, 'white');
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            padding: 50px 30px;
            transform: translate(0, 0);
            transition: transform 0.5s ease;
            @include md {
                transform: translate(10px, -60px);
            }

            .head {
                font-size: 1.125rem;
                font-weight: 600;
                margin-bottom: 23px;
                text-transform: uppercase;
            }

            .text {
                font-size: 1.25rem;
            }

            .content-col {
                display: flex;
                gap: 35px;
            }

            .icon {
                position: relative;
                display: inline-block;

                // &::before,
                // &::after {
                //     content: '';
                //     display: inline-block;
                //     border-width: 5px;
                //     border-style: map-get($borders, 'solid');
                //     border-radius: 50%;
                //     position: relative;
                // }

                // &::before {
                //     width: 100px;
                //     height: 100px;
                //     border-bottom-color: rgba(map-get($colors, 'white'), 0.3);
                //     border-right-color: rgba(map-get($colors, 'white'), 0.3);
                //     border-top-color: rgba(map-get($colors, 'white'), 0);
                //     border-left-color: rgba(map-get($colors, 'white'), 0);
                //     top: 0px;
                //     left: 0px;
                //     animation: rotate-animation 1.3s linear 0s infinite;
                // }

                // &::after {
                //     width: 100px;
                //     height: 100px;
                //     border-bottom-color: rgba(map-get($colors, 'white'), 0.3);
                //     border-right-color: rgba(map-get($colors, 'white'), 0.3);
                //     border-top-color: rgba(map-get($colors, 'white'), 0);
                //     border-left-color: rgba(map-get($colors, 'white'), 0);
                //     top: -40px;
                //     left: 0;
                //     animation: anti-rotate-animation 1s linear 0s infinite;
                // }
            }

            @include md {
                position: inherit;
                opacity: 1;
                padding: 25px;

                .head {
                    margin-bottom: 15px;
                }

                .icon {
                    display: none;
                }

                .text {
                    font-size: 1rem;
                }
            }
        }
    }

    @keyframes rotate-animation {

        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes anti-rotate-animation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(-360deg);
        }
    }

    .core_value_one_before-entering{
        margin-top: 90px !important;
        position: relative !important;
    }

    .core_value_one_on-positon{
        margin-top: 90px !important;
        position: fixed !important;
    }

    .core_value_one_after-leaving{
        margin-top: 860px !important;
        position: relative !important;
    }

    .core_value_two_before-entering{
        margin-top: 10px !important;
        position: relative !important;
    }

    .core_value_two_on-positon{
        width: 500px !important;
        margin-top: 10px !important;
        position: fixed !important;
    }

    .core_value_two_after-leaving{
        margin-top: 2600px !important;
        position: relative !important;
    }

    @media only screen and (max-width: map-get($brakpoint, 'md')){

        .core_value_one_before-entering{
            margin-top: 0px !important;
            position: relative !important;
        }
        
        .core_value_one_on-positon{
            margin-top: 0px !important;
            position: relative !important;
        }
        
        .core_value_one_after-leaving{
            margin-top: 0px !important;
            position: relative !important;
        }

        .core_value_two_before-entering{
            margin-top: 0px !important;
            position: relative !important;
        }
        
        .core_value_two_on-positon{
            margin-top: 0px !important;
            position: relative !important;
        }
        
        .core_value_two_after-leaving{
            margin-top: 0px !important;
            position: relative !important;
        }

    }

    // - Our Core Values ------------------
    .core-values {
        background-color: $bg-four;
        padding-bottom: 90px;
        position: relative;

        .section-title {
            font-family: Outfit;
            font-size: 50px;
            font-weight: 300;
            line-height: 65px;
            letter-spacing: 0em;
            text-align: left;

            b {
                background: -webkit-linear-gradient(-90.92deg, #67B094 7.85%, #BAD48A 57.45%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-family: Raleway;
                font-size: 55px;
                font-weight: 600;
                line-height: 61px;
                letter-spacing: 0em;
                text-align: left;
            }

        }

        .section-text {
            font-family: Outfit;
            font-size: 20px;
            font-weight: 300;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;
        }


        .enable{
            background-color: chartreuse;
        }

        .onenter{
            position: fixed !important;
            margin-top: 100px !important;
            background-color: rgb(40, 72, 61) !important;
        }

        .sticky-top {
            margin-top: 200px;
            padding-top: 100px;
            width: 550px;

            @include md {
                padding-top: 25px;
                width: 100%;
            }
        }

        @include md {
            padding-bottom: 25px;
        }
    }

    .col-box {
        background-color: map-get($colors, 'white');
        padding: 48px;
        margin-bottom: 12px;
        box-shadow: -2px 4px 4px rgba(map-get($colors, 'black'), 0.03);

        &:first-child {
            margin-top: 90px;

            @include md {
                margin-top: 25px;
            }
        }

        .head {
            display: flex;
            align-items: center;
            gap: 40px;
            margin-bottom: 15px;

            .title {
                font-weight: 600;
            }

            span {
                color: $primary;
            }
        }

        .content {
            margin: 0;
            color: #7E7E7E;
            font-family: Outfit;
            font-size: 20px;
            font-weight: 300;
            line-height: 1.5;
            letter-spacing: 0em;
            text-align: left;
        }

        @include md {
            padding: 25px;
        }
    }

    // - Overview ------------------
    .overview-section {
        padding: 90px;

        .bg-overview {
            background-color: #0B244D;
            color: map-get($colors, 'white');
            border-radius: 5px;
            padding: 25px 0;

            .head {
                text-transform: uppercase;
            }

            img {
                @include md {
                    max-width: 100%;
                }
            }
        }

        .overview-list {
            margin: 0;
            padding: 0;
            display: grid;
            grid-template-columns: 50% 50%;
            list-style: none;
            text-align: center;

            li {
                position: relative;

                &:hover {
                    &::after {
                        opacity: 1;
                        transition: all 0.5s ease;
                        transform: translate(-50%, -50%) scale(1.1);
                    }
                }

                &::after {
                    content: '';
                    background: -webkit-linear-gradient(99.92deg, #67B094 7.85%, #BAD48A 57.45%);
                    animation: backgroundAnimation 5s ease-in-out infinite;
                    background-size: 400% 400%;
                    border-radius: 50px;
                    display: inline-block;
                    height: 75px;
                    position: absolute;
                    width: 75px;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%) scale(0);
                    transition: all 0.5s ease;
                    z-index: 1;
                    opacity: 0;
                }
            }

            .label {
                display: block;
                font-size: 6rem;
                font-weight: 700;
                position: relative;
                z-index: 2;
            }

            .value {
                display: block;
                font-size: 1.25rem;
                opacity: 0.6;
                max-width: 50%;
                margin: 0 auto;
                position: relative;
                z-index: 2;
            }

            @include md {
                grid-template-columns: auto;

                .label {
                    font-size: 2rem;
                }

                .value {
                    font-size: 1rem;
                }
            }
        }

        @include md {
            padding: 25px;
        }
    }

    // - Core Value ------------------
    .core-value {
        padding-bottom: 90px;

        // .pin-spacer{
        //     background-color: black;
        //     margin-top: 0px;
        // }

        .sticky-top {
            padding-top: 60px;
            top: 60px;
            // width: 550px;
            width: 100%;

            .section-title{
                font-family: Outfit;

                span{
                    background: -webkit-linear-gradient(-90.92deg, #67B094 7.85%, #BAD48A 57.45%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 400;
                }

                color: black;
                font-weight: 400;

            }

            @include md {
                padding-top: 25px;
                width: 100% !important; 
            }
        }

        .core-col {
            padding: 90px 0;

            .image {
                margin-bottom: 25px;
                display: block;

                @include md {
                    margin-bottom: 15px;

                    img {
                        max-width: 100%;
                    }
                }
            }

            .title {

                font-family: Outfit;
                font-size: 50px;
                font-weight: 300;
                line-height: 65px;
                letter-spacing: 0em;
                text-align: left;

            }

            @include md {
                padding: 25px 0;
            }
        }

        @include md {
            padding-bottom: 25px;
        }
    }

    .core-value-two{
        margin-top: 120px;
    }

    .core-nav {
        list-style: none;
        padding: 0;
        margin-top: 100px;
        counter-reset: item;

        li {
            margin-bottom: 25px;

            a {
                font-size: 2rem;
                color: $text-one;
                text-decoration: none;
                opacity: 0.35;
                font-weight: 600;
                display: flex;
                flex-direction: row;

                &.active {
                    opacity: 1;
                    padding-left: 20px;

                    .idicator{
                        width: 6px;
                        height: 50px;
                        background: linear-gradient(to bottom, #67B094,#BAD48A);
                        position: absolute;
                        margin-left: -20px;
                    }
                }

                &::before {
                    padding-right: 20px;
                    counter-increment: item;
                    content: counter(item, decimal-leading-zero);
                }

                @include md {
                    font-size: 1.2rem;
                }
            }

            @include md {
                margin-bottom: 20px;
            }
        }

        @include md {
            margin-top: 25px;
        }
    }

    // - Let Connect Section ------------------
    .connect-section {
        background-color: $bg-four;
        padding: 90px 0;

        .title {
            font-size: 3.375rem;

            @include md {
                font-size: 1.5rem;
            }
        }

        .more-button {
            color: $text-one;
            float: right;
            font-size: 1.5rem;

            .star-icon {
                filter: brightness(10%) contrast(90%);
                left: 0;
                transition: all 0.5s ease-in;
            }

            &:hover {
                .star-icon {
                    left: 8px;
                    transition: all 0.5s ease-in;
                }
            }

            @include md {
                float: none;
            }
        }

        @include md {
            padding: 25px 0;
        }
    }