/* ------------------
- OUR PRODUCT Page 
---------------------*/

// - Product Main ------------------

.main-section {

    @extend .section-align;
    background: url(../../images/our-products/product-background-01.png) no-repeat center center / cover;
    background-color:  #0B1F40;
    color: map-get($colors, 'white');
    text-align: center;
    position: relative;
    width: 100%;

    .section-title {
        margin-bottom: 25px;
        font-family: Outfit;
        font-size: 50px;
        font-weight: 300;
        line-height: 65px;
        letter-spacing: 0em;
        text-align: center;

    }

    .text {
        color: white;
        font-family: Outfit;
        font-size: 24px;
        font-weight: 100;
        line-height: 1.5;
        letter-spacing: 0em;
        text-align: center;

        @include md {
            font-size: 1rem;
        }
    }

    .brands {
        position: absolute;
        background-color: $bg-four;
        border-radius: 50px;
        color: black;
        display: inline-flex;
        align-items: center;
        gap: 10px;
        transition: all 0.3s ease-in;
        animation: floating infinite linear 5s;
        bottom: 250px;
        left: 25%;

        &:first-child {
            top: 68%;
            right: 57%;
            bottom: auto;
            left: auto;
        }

        &:nth-child(2) {
            top: 26%;
            right: 50%;
            bottom: auto;
            left: auto;
        }

        &:nth-child(3) {
            top: 20%;
            right: 24%;
            bottom: auto;
            left: auto;
        }

        &:nth-child(4) {
            bottom: 38%;
            left: 15%;
        }

        &:nth-child(5) {
            top: 25%;
            left: 25%;
            bottom: auto;
            right: auto;
        }

        &:nth-child(6) {
            top: auto;
            left: auto;
            bottom: 20%;
            right: 35%;
        }


        &:hover {
            padding: 15px 50px 15px 15px;
            transition: all 0.3s ease-in;

            .content {
                display: inline-flex;
                transition: all 0.3s ease-in;
            }
        }

        .logo {
            background-color: map-get($colors, 'white');
            width: 50px;
            height: 50px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            box-shadow: 0px 1px 4px rgba($bg-eight, 0.3);
        }

        .content {
            display: none;
            overflow: hidden;
            flex-direction: column;
            text-align: left;
            transition: all 0.3s ease-in;

            .name {
                font-size: 1.25rem;
                font-weight: 400;
                transition: all 0.3s ease-in;
                
            }

        }

        @include md {
            display: none;
        }
    }

    @include md {
        padding-top: 100px;
    }

}

@keyframes floating {
    from {
        transform: rotate(0deg) translate(-10px) rotate(0deg)
    }

    to {
        transform: rotate(360deg) translate(-10px) rotate(-360deg)
    }
}

// - Product Item ------------------
.product-section {
    padding: 100px 0px;

    .section-container{

        margin: auto;
        width: 100%;
        height: 100%;
        display: flex;
        max-width: 1600px;
        flex-direction: row;
        position: relative;


        .product-item-list {
            padding: 0px 140px;
            font-size: 0;
            display: flex;
        
            .even-list{
                display: flex;
                flex-direction: column;
                width: 50%;
            }
        
            .odd-list{
                display: flex;
                flex-direction: column;
                margin-left: 100px;
                margin-top: 50px;
                width: 50%;
            }
        
            .item {
                display: inline-flex;
                flex-direction: column;
                width: 100%;
                cursor: pointer;
                margin-bottom: 80px;
        
                // &:nth-child(even) {
                //     float: right;
                // }
        
                &:hover {
                    .description {
                        opacity: 1;
                        max-height: 100vh;
                        transition: all 0.9s ease;
                    }
                }
        
                .top {
                    position: relative;
                }
        
                .image {
                    height: 574px;
        
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius:6px;
                    }
        
                    @include md {
                        height: 250px;
                    }
                }
        
                .description {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    color: map-get($colors, 'white');
                    padding: 40px;
                    -webkit-backdrop-filter: blur(10px);
                    backdrop-filter: blur(10px);
                    background-color: rgba($bg-three, 0.8);
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
                    line-height: 1.5;
                    font-size: 1.25rem;
                    opacity: 0;
                    max-height: 0;
                    transition: all 0.9s ease;
        
                    @include md {
                        padding: 25px;
                        font-size: 1rem;
                    }
                }
        
                .head {
                    margin-top: 20px;
                    color: black;
        
                    .text {
                        font-size: 1.25rem;
                    }
        
                    @include md {
                        margin-top: 15px;
        
                        .name {
                            font-family: Outfit;
                            font-size: 32px;
                            font-weight: 400;
                            line-height: 42px;
                            letter-spacing: 0em;
                            text-align: left;

                        }
        
                        .text {
                            font-family: Outfit;
                            font-size: 24px;
                            font-weight: 300;
                            line-height: 31px;
                            letter-spacing: 0em;
                            text-align: left;
                        }
                    }
                }
        
                @include md {
                    width: 100%;
                    margin-bottom: 15px;
                }
            }

        }
    }

}


@media only screen and (max-width: map-get($brakpoint, 'xl')) {

    .product-section {
        padding: 80px 0px;
        display: flex;
        height: fit-content;

            .section-container{
                margin: auto;
                width: 100%;
                height:  100% ;
                display: flex;
                max-width: map-get($brakpoint, 'lg');
                flex-direction: row;
                position: relative;

                .product-item-list {
                    padding: 0px 40px;
                    font-size: 0;
                    display: flex;
                
                    .even-list{
                        display: flex;
                        flex-direction: column;
                    }
                
                    .odd-list{
                        display: flex;
                        flex-direction: column;
                        margin-left: 50px;
                        margin-top: 25px;
                    }
                
                    // display:'flex',flexDirection:'column',marginLeft:'100px',marginTop:'50px'
                    
                    .item {
                        display: inline-flex;
                        flex-direction: column;
                        width: 100%;
                        cursor: pointer;
                        margin-bottom: 80px;
                
                        // &:nth-child(even) {
                        //     float: right;
                        // }
                
                        &:hover {
                            .description {
                                opacity: 1;
                                max-height: 100vh;
                                transition: all 0.9s ease;
                            }
                        }
                
                        .top {
                            position: relative;
                        }
                
                        .image {
                            height: 474px;
                
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius:6px;
                            }
                
                            @include md {
                                height: 250px;
                            }
                        }
                
                        .description {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            color: map-get($colors, 'white');
                            padding: 25px;
                            -webkit-backdrop-filter: blur(10px);
                            backdrop-filter: blur(10px);
                            background-color: rgba($bg-three, 0.8);
                            line-height: 1.5;
                            font-size: 1.1rem;
                            opacity: 0;
                            max-height: 0;
                            transition: all 0.9s ease;
                
                            @include md {
                                padding: 25px;
                                font-size: 1rem;
                            }
                        }
                
                        .head {
                            margin-top: 20px;
                
                            .text {
                                font-size: 1.25rem;
                            }
                
                            @include md {
                                margin-top: 15px;
                
                                .name {
                                    font-size: 1.25rem;
                                }
                
                                .text {
                                    font-size: 1rem;
                                }
                            }
                        }
                
                        @include md {
                            width: 100%;
                            margin-bottom: 15px;
                        }
                    }
                }

            }

    }
}

@media only screen and (max-width: map-get($brakpoint, 'md')) {

    .product-section {
        padding: 80px 20px;
        display: flex;
        height: fit-content;

            .section-container{
                margin: auto;
                width: 100%;
                height:  100%;
                display: flex;
                max-width: map-get($brakpoint, 'sm');
                flex-direction: row;
                position: relative;

                .product-item-list {
                    font-size: 0;
                    padding: 0px 0px;
                    display: flex;
                    flex-direction: column;

                
                    .even-list{
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                    }
                
                    .odd-list{
                        display: flex;
                        flex-direction: column;
                        margin-left: 0px;
                        margin-top: 0px;
                        width: 100%;
                    }
                    
                    .item {
                        display: inline-flex;
                        flex-direction: column;
                        width: 100%;
                        cursor: pointer;
                        margin-bottom: 40px;
                
                        // &:nth-child(even) {
                        //     float: right;
                        // }
                
                        &:hover {
                            .description {
                                opacity: 1;
                                max-height: 100vh;
                                transition: all 0.9s ease;
                            }
                        }
                
                        .top {
                            position: relative;
                        }
                
                        .image {
                            height: 100%;
                            width: 100%;
                
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius:6px;
                            }
                
                        }
                
                        .description {
                            position: absolute;
                            font-family: Outfit;
                            font-weight: 200;
                            background-color: beige;
                            left: 0;
                            bottom: 0;
                            color: map-get($colors, 'white');
                            padding: 25px;
                            -webkit-backdrop-filter: blur(10px);
                            backdrop-filter: blur(10px);
                            background-color: rgba($bg-three, 0.8);
                            line-height: 1.5;
                            font-size: 0.9rem;
                            opacity: 0;
                            transition: all 0.9s ease;
                
                        }
                
                        .head {
                            margin-top: 20px;
                
                            .text {
                                font-size: 1.25rem;
                                color: white;
                            }
                
                            @include md {
                                margin-top: 15px;
                
                                .name {
                                    font-size: 1.25rem;
                                }
                
                                .text {
                                    font-size: 1rem;
                                    color: black;
                                }
                            }
                        }
                
                        @include md {
                            width: 100%;
                            margin-bottom: 15px;
                        }
                    }
                }

            }

    }
}