@use '../others//variables' as theme;

@use "sass:map";

.let-connect-us {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #F6F6F6;
    // padding: 64px 0px;

    .container {
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: 1200px;

      .title-container{
        width: 50%;
        height: 100%;

        .let-connect-title {
          text-align: center;
          height: 400px;
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 500;
          font-size: 50px;
          line-height: 1.3;
          display: flex;
          text-align: left;
          align-items: center;
          /* white-space: nowrap; */
          color: #525053;
        }
        
      }

      .button-container{
        width: 50%;

        .button-center{
          margin: auto;
          min-height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transform: translateX(20px);
          height: 100%;

          .botton-container {
            margin-top: -20px;
          }
        }

        .let-connect-title span{
          font-weight: 300 !important;
        }

      }


    }
      
}

@media only screen and (max-width: map-get($brakpoint, 'xl')) {

  .let-connect-us {
    width: 100%;
    background-color: #F9F6F5;
    display: flex;
    flex-direction: row;
    padding: 0px 20px; 
    // padding: 64px 0px;

    .container {
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: map-get(theme.$brakpoint, 'lg');

      .title-container{
        width: 50%;
        height: 100%;

        .let-connect-title {
          text-align: center;
          height: 400px;
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 500;
          font-size: 40px;
          line-height: 1.3;
          display: flex;
          text-align: center;
          align-items: center;
          color: #525053;
        }
        
      }

      .button-container{
        width: 50%;
        height: 100%;

        .let-connect-title span{
          font-weight: 300 !important;
        }

      }


    }
      
}

  // .let-connect-us{

  //   flex-direction: column;
    
  //   .button-center{
  //       margin-top: 0px;
  //       min-height: fit-content;
  //   }

  //   .container{
  //     flex-direction: column;
  //     max-width: map-get(theme.$brakpoint, 'lg');
  //     padding: 50px;

  //     .title-container{
        
  //       width: 100%;
  //       height: 100%;

  //       .let-connect-title{
  //         text-align: center;
  //         height: auto;
  //         font-family: 'Raleway';
  //         font-style: normal;
  //         text-align: center;
  //         justify-content: center;
  //         font-weight: 500;
  //         font-size: 26px;
  //         line-height: 1.3;
  //       }
  //     }

  //     .button-container{
  //       width: 100%;
  //       height: 100%;
  //     }


  //   }
  // }
  
}

@media only screen and (max-width: 960px){

  .let-connect-us{
      flex-direction: column;
      
      .button-center{
          margin-top: 0px;
          min-height: fit-content;
      }

      .container{
        flex-direction: column;
        max-width: map-get(theme.$brakpoint, 'sm');

        .title-container{
          width: 100%;
          height: 100%;

          .let-connect-title{
            text-align: center;
            height: auto;
            font-family: 'Raleway';
            font-style: normal;
            text-align: center;
            justify-content: center;
            font-weight: 500;
            font-size: 24px;
            line-height: 1.3;
          }
        }

        .button-container{
          width: 100%;
          height: 100%;
        }


      }
    }

}

