// Main color
$primary: #F3684A;
$p_light: #ff886d;
$secondery: #348D55;
$tertiary: #4B96BF;
$theme: #3A314C;

// breakpoints
$brakpoint: (
    'xs': 0,
    'sm': 520px,
    'md': 960px,
    'lg': 1024px,
    'xl': 1366px,
    'xxl': 1600px,
    // 'xxl': 1920px,
);


// Colours
$colors: (
    "primary": $primary,
    "secondery": $secondery,
    "blue": #1919e6,
    "red": #e61919,
    "yellow": #e6e619,
    "green": #19e635,
    "orange": #ffa600,
    "purple": #9900ff,
    "gray": #808080,
    "black": black,
    "white": white,
);


// Text Color
$text-one: #3A314C;
$text-two: #5A536B;
$text-three: #7E7E7E;
$text-four: #EEEEEE;

// Background Color
$bg-one: #1C1C1C;
$bg-two: #2D3338;
$bg-three: #101329;
$bg-four: #F9F6F5;
$bg-five: #FBFBFB;
$bg-six: #01011D;
$bg-seven: #4B4160;
$bg-eight: #998585;

$border-color: #E6E6E6;

// Spacing
$base-padding: 15px;
$base-margin: 15px;

//Border
$border-width: 1px;
$border-radus: 10px;
$borders: (
    'solid': solid,
    'dashed': dashed,
);

//Font
$base-font-size: 1rem;
$sm-font-size: $base-font-size * 0.75;
$lg-font-size: $base-font-size * 1.5;
$xl-font-size: $base-font-size * 2