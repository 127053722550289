// Page Loading ----------------
.loading {
    background-color: #0B244D;
    position: absolute;
    left: 0px;
    top: 0px;
    font-size: 64px;
    font-weight: 600;
    height: 100vh;
    width: 100%;

 

    &.hide {
        transform: scale(2);
        opacity: 0;
        transition: all 2000ms cubic-bezier(0.075, 0.82, 0.165, 1);
        animation: scaleAnimation 0.5s;
        filter: blur(50px);
        pointer-events: none;
    }

    .content {
        width: 200px;
        height: fit-content;
        display: flex;
        position: relative;
        margin: auto;
        flex-direction: column;

        img{
            margin-bottom: 47px;
        }

        .mobisense{
            font-family: Poppins;
            font-size: 24px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            margin-bottom:5px;
            color: white;
        }

        .information{
            font-family: Poppins;
            font-size: 20px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
            color: white;
        }
    }




}
