// Media Query Brakpoint ----------------
$brakpoint: (
    'sm': 720px,
    'md': 980px,
    'lg': 1024px,
    'xl': 1366px,
    'xxl': 1560px,
);



@mixin xs {
    @media (max-width: map-get($brakpoint, 'xs')) {
        @content;
    }
}

@mixin sm {
    @media (max-width: map-get($brakpoint, 'sm')) {
        @content;
    }
}

@mixin md {
    @media (max-width: map-get($brakpoint, 'md')) {
        @content;
    }
}

@mixin lg {
    @media (max-width: map-get($brakpoint, 'lg')) {
        @content;
    }
}

@mixin xl {
    @media (max-width: map-get($brakpoint, 'xl')) {
        @content;
    }
}

@mixin xxl {
    @media (max-width: map-get($brakpoint, 'xxl')) {
        @content;
    }
}

// Demo Responsive 
.test-responsive {
    @include xs {
        font-style: normal;
    }

    @include sm {
        font-style: normal;
    }
}

