.working-progress-section{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: fit-content;

    .working-progress{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: fit-content;
        text-align: center;
        margin: 100px 0px;
    }
}