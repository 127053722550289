// Header ----------------

.on-scroll-header {
    background: rgba(255, 255, 255, 0.879);
    box-shadow: 0 2px 8px 0 rgba(0, 10, 56, 0.12);
    position: absolute;
    animation: fade-in 0.3s !important;
}

.header{
    display: inline-flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 3;
    justify-content: right;
    
    .top-header {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 3;
        padding: 20px 20px;
        transition-duration: 0.3s;
        
        // &::before {
        //     content: '';
        //     background-color: rgba(map-get($colors, 'black'), 0.25);
        //     backdrop-filter: blur(50px);
        //     -webkit-backdrop-filter: blur(80px);
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     z-index: -1;
        // }

    }

    .main-menu {
        background-color: #0B1F40;
        position: absolute;
        flex-direction: column;
        justify-content: space-between;
        top: 0;
        width: 100%;
        height: 0px;
        z-index: 99;
        opacity: 0;
        display: flex;
        pointer-events: none;
        z-index: -1000;
        transition: all 0.3s ease-in;
    
        div {
            opacity: 0;
            transform: translateY(5px);
            transition: all 0.2s ease;
        }
    
        &.show {
            display: flex;
            max-width: 800px;
            width: 100%;
            height: 100vh;
            z-index: 1000;
            pointer-events: auto;
            opacity: 1;
            transition: all 0.3s ease-in;
            backdrop-filter: blur(100px);
            margin-left: auto;
    
            div {
                opacity: 1;
                transform: translateY(0);
                transition: all 0.6s ease-in;
            }
        }
    
        .top-section {
            position: relative;
            padding: 20px 20px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
    
            .top-section-logo {
                height: 33px;
                display: flex;
                transform: scale(1);
                margin-left: 0px;
            }
    
            .close-menu {
                background: none;
                padding: 0;
                border: none;
                width: 25px;
                height: 25px;
                position: relative;
                transition: 350ms cubic-bezier(.8, .5, .2, 1.4);
                margin-right: 10px;
    
                &:hover {
                    transform: scale(1.2);
                    transition: 350ms cubic-bezier(.8, .5, .2, 1.4);
                }
    
                &::before,
                &::after {
                    content: '';
                    background-color: white;
                    width: 100%;
                    height: 3px;
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    top: 50%;
                }
    
                &::before {
                    transform: rotate(45deg);
                }
    
                &::after {
                    transform: rotate(-45deg);
                }
            }
    
            @include md {
                top: 15px;
                padding: 0 20px;
            }
        }

        .section-container{
        padding-left: 50px;
        }
    
        .main-nav {
            padding: 0;
            margin-bottom: 96px;
            display: flex;
            flex-direction: column;
            counter-reset: item;
            list-style: none;
    
            li {
                margin-bottom: 20px;
                display: inline-flex;
                align-items: center;
                transition-duration: 0.3s;
    
    
                &:last-child {
                    margin: 0;
                }
    
                a {
                    color: map-get($colors, 'white');
                    text-decoration: none;
                    font-size: 2rem;
                    opacity: 0.4;
                    transition: all 0.5s ease;
                    display: inline-flex;
                    align-items: center;
                    position: relative;
    
                    // &::after {
                    //     content: '';
                    //     background: linear-gradient(-45deg, $primary, $p_light, darken($primary, 5%), #23d5ab);
                    //     background-size: 400% 400%;
                    //     width: 85px;
                    //     height: 85px;
                    //     border-radius: 50%;
                    //     display: inline-block;
                    //     z-index: -1;
                    //     position: absolute;
                    //     left: 0;
                    //     transform: scale(0);
                    //     transition: all 0.5s ease;
                    //     animation: iconAnimationOut 0.5s ease-in-out, backgroundAnimation 5s ease-in-out infinite;
                    // }
    
                    &:hover,
                    &.active {
                        opacity: 1;
                        font-size: 3.125rem;
                        transition: all 0.5s ease;
    
                        &::after {
                            transition: all 0.5s ease;
                            transform: scale(1);
                            left: calc(100% - 40px);
                        }
    
                        @include md {
                            font-size: 1.25rem;
    
                            &::after {
                                width: 50px;
                                height: 50px;
                            }
                        }
                    }
    
                    // &::before {
                    //     padding-right: 20px;
                    //     counter-increment: item;
                    //     content: counter(item, decimal-leading-zero);
                    // }
    
                    @include md {
                        font-size: 1.2rem;
                    }
                }
            }
    
            @include md {
                margin-bottom: 50px;
            }
        }
    
        .hr {
            border-color: map-get($colors, 'white');
            border-width: 2px;
            margin-top: 20px;
            margin-bottom: 20px;
            position: relative;
            opacity: 1;
            height: 15px;
    
            &::before {
                content: '';
                background-color: map-get($colors, 'white');
                height: 13px;
                width: 100px;
                position: absolute;
                left: 0;
                top: 0;
                display: inline-block;


                @include md {
                    height: 8px;
                }

            }
        }
    
        .nav-social {
            align-items: center;
            display: flex;
            gap: 45px;
            list-style: none;
            margin-bottom: 30px;
            padding: 0;

            a{

                img{
                    width: 20px;
                    height: 20px;
                }

                .active{
                    display: none;
                }
                .default{
                    display: block;
                }
            }
    
            a:hover{
                .active{
                    display: block;
                }
                .default{
                    display: none;
                }
            }


            @include md {
                margin-bottom: 20px;
            }
        }
    
        .sub-menu {
            text-align: right;
            list-style: none;
            margin: 0;
            padding: 0;
    
            li {
                margin-bottom: 20px;
    
                &:last-child {
                    margin-bottom: 0;
                }
    
                a {
                    color: map-get($colors, 'white');
                    text-decoration: none;
                    font-size: 1.25rem;
                    opacity: 0.4;
                    position: relative;
                    display: inline-flex;
                    align-items: center;
                    gap: 15px;
                    transition: all 0.5s ease;
    
                    &:after {
                        content: '';
                        background-color: map-get($colors, 'white');
                        width: 20px;
                        height: 3px;
                        display: inline-block;
                        transition: all 0.5s ease;
                    }
    
                    &:hover {
                        color: $primary;
                        opacity: 1;
                        transition: all 0.5s ease;
    
                        &::after {
                            transition: all 0.5s ease;
                            background-color: $primary;
                        }
                    }
                }
            }
    
            @include md {
                text-align: left;
            }
        }
    
        .more-button {
            float: right;
    
            @include md {
                float: none;
            }
        }
    
        .let-connect-button-container{
            display: flex;
            justify-content: right;
            margin-right: -20px;
            padding-bottom: 100px;
        }
    }

}



.main-logo {
    height: 33px;
    display: inline-flex;

    img {
        display: none;
        opacity: 0;
        transition: all 0.3s ease;
        max-width: 100%;
        max-height: 100%;
    }

    @include md {
        height: 33px;
    }
}

.nav {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
}

// Dark & Light Mode ----------------
.light {

    // &::before {
    //     background-color: rgba(map-get($colors, 'white'), 0.5);
    // }

    .navbar-nav {
        li {
            a {
                color: $text-one;
                transition: all 0.3s ease;
            }
        }
    }

    .nav-button {

        .dark {
            opacity: 1;
            display: block;
            transition: all 0.3s ease;
        }

        .white {
            display: none;
            opacity: 0;
            transition: all 0.3s ease;
        }

    }

    .main-logo {
        // padding-right: 10px;
        .dark {
            opacity: 1;
            display: block;
            transition: all 0.3s ease;
        }
    }
}

.dark {

    .main-logo {

        .white {
            display: block;
            opacity: 1;
            transition: all 0.3s ease;
        }
    }

    .nav-button {

        .white {
            display: block;
            opacity: 1;
            transition: all 0.3s ease;
        }

        .dark {
            opacity: 0;
            display: none;
            transition: all 0.3s ease;
        }

    }
}

.navbar-nav {
    display: flex;
    gap: 50px;
    list-style: none;
    flex-direction: row;
    margin-left: auto;

    li {
        a {
            color: map-get($colors, 'white');
            transition: all 0.3s ease;
            text-transform: uppercase;
            text-decoration: none;
        }
    }

    @include md {
        display: none;
    }
}

.nav-button {
    background: none;
    border: none;
    position: relative;
    margin-left: 0px;

    &:hover {
        span {
            transform: scale(1.2);
            transition: 350ms cubic-bezier(.8, .5, .2, 1.4);
        }
    }

    span {
        width: 8px;
        height: 8px;
        border: 1.5px map-get($borders, 'solid') map-get($colors, 'white');
        display: block;
        border-radius: 50%;
        position: absolute;
        transition: all 0.3s ease;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            top: 0;
        }

        &:nth-child(1) {
            left: 0;
        }

        &:nth-child(2) {
            left: 11px;
        }

        &:nth-child(3) {
            right: 0;
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
            top: 11px;
        }

        &:nth-child(4) {
            left: 0;
            opacity: 0.6;
        }

        &:nth-child(5) {
            left: 11px;
        }

        &:nth-child(6) {
            right: 0px;
        }

        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9) {
            bottom: 0px;
        }

        &:nth-child(7) {
            left: 0px;
            opacity: 0.6;
        }

        &:nth-child(8) {
            left: 11px;
            opacity: 0.6;
        }

        &:nth-child(9) {
            right: 0px;
        }
    }

    @include md {
        margin-left: auto;
    }
}

// Main Menu ----------------

@media only screen and (max-width: map-get($brakpoint, 'md')) {
    .main-menu{
        display: flex;
        .container{
            bottom: 0;
            padding: 20px 20px;
            height: fit-content;
        }
        .let-connect-button-container{
            display: flex;
            justify-content: left;
            padding: 40px 10px;
            .botton-container{
                margin-right: auto !important;
            }
        }
    }
}

// Fixed Pagination ----------------
.fixed-pagination {
    display: none;
    flex-direction: column;
    position: fixed;
    right: 50px;
    top: 50%;
    text-align: right;
    transform: translateY(-50%);
    counter-reset: item;
    width: 110px;

    @include lg {
        display: none;
    }

    a {
        color: map-get($colors, 'white');
        text-decoration: none;
        cursor: pointer;
        font-size: 0;
        margin-bottom: 10px;
        display: inline-block;
        text-align: right;

        &.active {
            font-size: 1.25rem;
            margin-bottom: 0;

            &::before {
                font-size: 1.5rem;
                font-weight: 600;
                display: block;
            }

            &::after {
                height: 0;
                opacity: 0;
                display: none;
            }
        }

        &::before {
            counter-increment: item;
            content: counter(item, decimal-leading-zero);

        }

        &::after {
            content: '';
            width: 110px;
            height: 13px;
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            background-color: rgba(map-get($colors, 'white'), 0.2);
            display: inline-block;
        }
    }
}


.shine {
	position: relative;
	overflow: hidden;

	&::before {
		background: linear-gradient(
			to right,
			fade_out(#fff, 1) 0%,
			fade_out(#fff, 0.7) 100%
		);
		content: "";
		display: block;
		height: 100%;
		left: -75%;
		position: absolute;
		top: 0;
		transform: skewX(-25deg);
		width: 50%;
		z-index: 2;
	}

	&:hover,
	&:focus {
		&::before {
			animation: shine 0.85s;
		}
	}

	@keyframes shine {
		100% {
			left: 125%;
		}
	}
}