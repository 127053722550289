.our-service-card-section {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background: #FFFFFF;
}

.service-industry-card {
    display: flex;
    flex-direction: row;
    margin: auto;
    height: 600px;
    width: 70%;
}

.service-industry-description {
    margin-left: 0px;
 }

.our-services{



.wrapped-content{
    padding-left: 0px;
    padding-right: 0px;
}

.main-section{
    backdrop-filter: brightness(80%);
    display: flex;
    align-items: center;
    min-height: 100vh;

    div{
        margin: auto;
        width: fit-content;
        justify-content: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .button-container{
            display: flex;
            flex-direction: row;
            margin: auto;
            transform: translateX(20px);
            margin-top: 20px;
        }
        }
    
    .section-title{
        margin: auto;
        font-family: Outfit;
        font-style: normal;
        font-weight: 300;
        font-size: 50px;
        line-height: 130%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;

        .case-study{
            font-family: "Raleway";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22.14px;
            transform: translateY(40px);
        }
        }

    .sub-title{
        height: 62px;
        margin-top: 16px;
        font-family: Outfit;
        font-size: 24px;
        line-height: 130%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #EEEEEE;
        }

}

.first-section {
    display: flex;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 200px;
    background-color: rgb(249, 249, 249);

        .section-container {
            margin: auto;
            width: 100%;
            height: 547px;
            display: flex;
            max-width: 1600px;
            flex-direction: row;
            position: relative;

            .container-content {
                padding-left: 120px;
                max-width: 720px;
                width: 100%;
                margin: auto;

    
                .label {
                    font-family: Outfit;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 123%;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.16em;
                    color: #A09E9E;
                    text-transform: uppercase;
                }
    
                .title {
                    font-family: Outfit;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 50px;
                    line-height: 130%;
                    text-align: left;
                    overflow-wrap: break-word;
                    color:rgba(58, 49, 76, 1);

                    .bold{
                        font-weight: 400;
                    }
    
                    .color {
                        font-weight: 400;
                        background: -webkit-linear-gradient(-80deg, #67B094 7.85%, #BAD48A 57.45%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
    
                .description {
                    margin-top: 30px;
                    font-family: Raleway;
                    font-style: normal;
                    text-align: left;
                    font-size: 18px;
                    line-height: 150%;
                    color: rgba(0, 0, 0, 0.6);
                }
    

                .bullet-point-container{
                    display: flex;
                    flex-direction: column;
                    .bullet-item{
                        display: flex;
                        flex-direction: row;

                        .icon-container{
                            display: flex;
                            flex-direction: column;

                            .buttet-icon{
                                margin: auto;
                                width: 15px;
                                height: 15px;
                            }
                        }

                        .bullet-text{
                            margin-left: 8px;
                            font-family: Raleway;
                            font-size: 18px;
                            line-height: 27px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: rgba(0, 0, 0, 0.6);
                        }
                    }
                }

                .mobile-card-container{
                    display: none;
                    justify-content: center;
                    margin-top: 20px;
                    height: 300px;

                    .background-image{
                        position: absolute;
                        display: none;
                        width: 100%;
                        height: 300px;
                    }

                    .inner-card {
                        margin: auto;
                        font-family: Raleway;
                        position: relative;
                        font-weight: 400;
                        font-size: 18px;
                        text-align: left;
                        z-index: 1;
                        line-height: 27px;
                        width: 80%;
                        height: fit-content;
                        color: rgba(58, 49, 76, 0.8);
                        margin-left: auto;
                        margin-top: 50px;
                        background-color: white;
                        border-radius: 6px;
                        padding: 0px 20px;
                        box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
        
                        .card-icon {
                            margin-left: 15px;
                            position: absolute;
                            margin-top: -20px;
                            width: 50px;
                            height: 50px;
                        }
        
                        .title {
                            font-family: Raleway;
                            padding-top: 60px;
                            font-size: 18px;
                            font-weight: 300;
                            line-height: 27px;
                            letter-spacing: 0em;
                            text-align: left;
                        }
        
                        .description {
                            font-family: Raleway;
                            margin-top: 0px;
                            font-size: 30px;
                            font-weight: 300;
                            line-height: 45px;
                            letter-spacing: 0em;
                            font-style: italic;
                            text-align: left;
                        }
                    }

                }
    
    
            }
    
            .container-image {
                height: auto;
                width: 100%;
                display: flex;
    
                .image-container {
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: fit-content;
                    margin-left: auto;
    
                    .circle-decoration {
                        width: 35px;
                        height: 70px;
                        position: absolute;
                        z-index: 1;
                        top: 70px;
                        right: 0px;
                    }
    
                    .circle-decoration:nth-child(2) {
                        width: 70px;
                        height: 70px;
                        position: absolute;
                        margin-top: auto;
                        z-index: 0;
                        left: -20px;
                        bottom: 70px;
                    }
    
                    .display-image {
                        position: relative;
                        margin-left: auto;
                        clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                        height: 547px;
                        width: auto;
                    }

                    .inner-card {
                        font-family: Raleway;
                        position: relative;
                        font-weight: 400;
                        font-size: 18px;
                        text-align: left;
                        z-index: 1;
                        line-height: 27px;
                        width: 500px;
                        height: fit-content;
                        color: rgba(58, 49, 76, 0.8);
                        margin-right: auto;
                        margin-left: -90px;
                        margin-top: -180px;
                        background-color: rgba(255, 255, 255, 1);
                        border-radius: 6px;
                        padding: 20px 20px;
                        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.03);
        
                        .card-icon {
                            margin-left: 15px;
                            position: absolute;
                            margin-top: -60px;
                            width: 70px;
                            height: 70px;
                        }
        
                        .title {
                            font-family: Raleway;
                            padding-top: 60px;
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 27px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: black;
                        }
        
                        .description {
                            padding-top: 30px;
                            font-family: Raleway;
                            font-size: 30px;
                            font-weight: 300;
                            line-height: 45px;
                            letter-spacing: 0em;
                            font-style: italic;
                            text-align: left;
                        }
                    }
                }
            }
    
        }
}

.second-section {

    display: flex;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 200px;
    background-color: rgb(249, 249, 249);

    .section-container {
        margin: auto;
        width: 100%;
        height: 547px;
        display: flex;
        max-width: 1600px;
        flex-direction: row;
        position: relative;

        .container-content {
            padding-left: 20px;
            max-width: 720px;
            width: 100%;
            margin: auto;

            .label {
                font-family: Outfit;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 123%;
                display: flex;
                align-items: center;
                letter-spacing: 0.16em;
                color: #A09E9E;
                text-transform: uppercase;
            }

            .title {
                font-family: Outfit;
                font-style: normal;
                font-weight: 300;
                font-size: 50px;
                line-height: 130%;
                text-align: left;
                overflow-wrap: break-word;
                color:rgba(58, 49, 76, 1);

                .bold{
                    font-weight: 400;
                }

                .color {
                    font-weight: 400;
                    background: -webkit-linear-gradient(-80deg, #67B094 7.85%, #BAD48A 57.45%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .description {
                margin-top: 30px;
                font-family: Raleway;
                font-style: normal;
                text-align: left;
                font-size: 18px;
                line-height: 150%;
                color: rgba(0, 0, 0, 0.6);
            }


            .bullet-point-container{
                display: flex;
                flex-direction: column;
                .bullet-item{
                    display: flex;
                    flex-direction: row;

                    .icon-container{
                        display: flex;
                        flex-direction: column;

                        .buttet-icon{
                            margin: auto;
                            width: 15px;
                            height: 15px;
                        }
                    }

                    .bullet-text{
                        margin-left: 8px;
                        font-family: Raleway;
                        font-size: 18px;
                        line-height: 27px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: rgba(0, 0, 0, 0.6);
                    }
                }
            }

            .mobile-card-container{
                display: none;
                justify-content: center;
                margin-top: 20px;
                height: 300px;

                .background-image{
                    position: absolute;
                    display: none;
                    width: 100%;
                    height: 300px;
                }

                .inner-card {
                    margin: auto;
                    font-family: Raleway;
                    position: relative;
                    font-weight: 400;
                    font-size: 18px;
                    text-align: left;
                    z-index: 1;
                    line-height: 27px;
                    width: 80%;
                    height: fit-content;
                    color: rgba(58, 49, 76, 0.8);
                    margin-left: auto;
                    margin-top: 50px;
                    background-color: white;
                    border-radius: 6px;
                    padding: 0px 20px;
                    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
    
                    .card-icon {
                        margin-left: 15px;
                        position: absolute;
                        margin-top: -20px;
                        width: 50px;
                        height: 50px;
                    }
    
                    .title {
                        font-family: Raleway;
                        padding-top: 60px;
                        font-size: 22px;
                        font-weight: 300;
                        line-height: 28px;
                        letter-spacing: 0em;
                        text-align: left;
                    }
    
                    .description {
                        font-family: Raleway;
                        margin-top: 0px;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 25px;
                        letter-spacing: 0em;
                        text-align: left;
                    }
                }

            }

        }

        .container-image {
            height: auto;
            width: 100%;
            display: flex;
            
            .image-container {
                height: auto;
                display: flex;
                flex-direction: column;
                position: relative;
                width: fit-content;
                margin-right: auto;
                overflow: hidden;
            

                .circle-decoration {
                    width: 35px;
                    height: 70px;
                    position: absolute;
                    z-index: 1;
                    top: 70px;
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);
                }

                .circle-decoration:nth-child(2) {
                    width: 70px;
                    height: 70px;
                    position: absolute;
                    margin-top: auto;
                    z-index: 0;
                    right: 45px;
                    bottom: 70px;
                }

                .display-image {
                    position: relative;
                    margin-left: -10px;
                    clip-path:  polygon(0px 0px, 100% 0px, 85% 100%, 0% 100%);
                    height: 547px;
                    width: fit-content;

                }

                .inner-card {
                    font-family: Raleway;
                    position: relative;
                    font-weight: 400;
                    font-size: 18px;
                    text-align: left;
                    z-index: 1;
                    line-height: 27px;
                    width: 500px;
                    height: fit-content;
                    color: rgba(58, 49, 76, 0.8);
                    margin-left: auto;
                    margin-top: -180px;
                    background-color: rgba(255, 255, 255, 1);
                    border-radius: 6px;
                    padding: 20px 20px;
                    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.03);
    
                    .card-icon {
                        margin-left: 15px;
                        position: absolute;
                        margin-top: -60px;
                        width: 70px;
                        height: 70px;
                    }
    
                    .title {
                        font-family: Raleway;
                        padding-top: 60px;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 27px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: black;
                    }
    
                    .description {
                        padding-top: 30px;
                        font-family: Raleway;
                        font-size: 30px;
                        font-weight: 300;
                        line-height: 45px;
                        letter-spacing: 0em;
                        font-style: italic;
                        text-align: left;
                    }
                }
            }
        }

    }

}

}

 @media only screen and (max-width: map-get($brakpoint, 'xl')) {

    .our-services{


        .first-section {
            display: flex;
            width: 100%;
            padding-top: 100px;
            padding-bottom: 100px;
            background-color: rgb(249, 249, 249);
        
            .section-container {
                
                margin: auto;
                width: 100%;
                height:  350px ;
                display: flex;
                max-width: map-get($brakpoint, 'lg');
                flex-direction: row;
                position: relative;
        
        
                .container-content {
                    padding-left: 120px;
                    max-width: 720px;
                    width: 100%;
                    margin: auto;
    
        
                    .label {
                        font-family: Outfit;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 123%;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.16em;
                        color: #A09E9E;
                    }
        
                    .title {
                        font-family: Outfit;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 30px;
                        line-height: 130%;
                        text-align: left;
                        overflow-wrap: break-word;
                        color:rgba(58, 49, 76, 1);
    
                        .bold{
                            font-weight: 400;
                        }
        
                        .color {
                            font-weight: 400;
                            color: rgb(243, 104, 74);
                        }
                    }
        
                    .description {
                        margin-top: 30px;
                        font-family: Raleway;
                        font-style: normal;
                        text-align: left;
                        font-size: 16px;
                        line-height: 150%;
                        color: rgba(0, 0, 0, 0.6);
                    }
        
    
                    .bullet-point-container{
                        display: flex;
                        flex-direction: column;
                        .bullet-item{
                            display: flex;
                            flex-direction: row;
    
                            .icon-container{
                                display: flex;
                                flex-direction: column;
    
                                .buttet-icon{
                                    margin: auto;
                                    width: 13px;
                                    height: 13px;
                                }
                            }
    
                            .bullet-text{
                                margin-left: 8px;
                                font-family: Raleway;
                                font-size: 16px;
                                line-height: 25px;
                                letter-spacing: 0em;
                                text-align: left;
                                color: rgba(0, 0, 0, 0.6);
                            }
                        }
                    }
    
                    .mobile-card-container{
                        display: none;
                        justify-content: center;
                        margin-top: 20px;
                        height: 300px;
    
                        .background-image{
                            position: absolute;
                            display: none;
                            width: 100%;
                            height: 300px;
                        }
    
                        .inner-card {
                            margin: auto;
                            font-family: Raleway;
                            position: relative;
                            font-weight: 400;
                            font-size: 18px;
                            text-align: left;
                            z-index: 1;
                            line-height: 27px;
                            width: 80%;
                            height: fit-content;
                            color: rgba(58, 49, 76, 0.8);
                            margin-left: auto;
                            margin-top: 50px;
                            background-color: white;
                            border-radius: 6px;
                            padding: 0px 20px;
                            box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
            
                            .card-icon {
                                margin-left: 15px;
                                position: absolute;
                                margin-top: -20px;
                                width: 50px;
                                height: 50px;
                            }
            
                            .title {
                                font-family: Raleway;
                                padding-top: 60px;
                                font-size: 22px;
                                font-weight: 300;
                                line-height: 28px;
                                letter-spacing: 0em;
                                text-align: left;
                            }
            
                            .description {
                                font-family: Raleway;
                                margin-top: 0px;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 25px;
                                letter-spacing: 0em;
                                text-align: left;
                            }
                        }
    
                    }
        
                }
        
                .container-image {
                    height: auto;
                    width: 100%;
                    display: flex;
        
                    .image-container {
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: fit-content;
                        margin-left: auto;
        
                        .circle-decoration {
                            width: 25px;
                            height: 50px;
                            position: absolute;
                            z-index: 1;
                            top: 40px;
                            right: 0px;
                            -webkit-transform: scaleX(-1);
                            transform: scaleX(-1);
                        }
        
                        .circle-decoration:nth-child(2) {
                            width: 50px;
                            height: 50px;
                            position: absolute;
                            margin-top: auto;
                            z-index: 0;
                            left: -20px;
                            bottom: 40px;
                        }
        
                        .display-image {
                            position: relative;
                            margin-left: auto;
                            clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                            width: 450px ;
                            height:  350px ;
                        }


                        .inner-card {
                            font-family: Raleway;
                            position: relative;
                            font-weight: 400;
                            font-size: 18px;
                            text-align: left;
                            z-index: 1;
                            line-height: 27px;
                            width: 350px;
                            height: fit-content;
                            color: rgba(58, 49, 76, 0.8);
                            margin-right: auto;
                            margin-left: -50px;
                            margin-top: -150px;
                            background-color: rgba(255, 255, 255, 1);
                            border-radius: 6px;
                            padding: 20px 20px;
                            box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.03);
            
                            .card-icon {
                                margin-left: 10px;
                                position: absolute;
                                margin-top: -40px;
                                width: 50px;
                                height: 50px;
                            }
            
                            .title {
                                font-family: Raleway;
                                padding-top: 30px;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 25px;
                                letter-spacing: 0em;
                                text-align: left;
                                color: black;
                            }
            
                            .description {
                                padding-top: 25px;
                                font-family: Raleway;
                                font-size: 24px;
                                font-weight: 300;
                                line-height: 32px;
                                letter-spacing: 0em;
                                font-style: italic;
                                text-align: left;
                            }
                        }
                    }
                }
        
            }
            }
    
        .second-section {
    
            display: flex;
            width: 100%;
            padding-top: 100px;
            padding-bottom: 100px;
            background-color: rgb(249, 249, 249);
        
            .section-container {
                margin: auto;
                width: 100%;
                height:  350px ;
                display: flex;
                max-width: map-get($brakpoint, 'lg');
                flex-direction: row;
                position: relative;
        
                .container-content {
                    padding-left: 20px;
                    max-width: 720px;
                    width: 100%;
                    margin: auto;
        
                    .label {
                        font-family: Outfit;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 123%;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.16em;
                        color: #A09E9E;
                    }
        
                    .title {
                        font-family: Outfit;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 30px;
                        line-height: 130%;
                        text-align: left;
                        overflow-wrap: break-word;
                        color:rgba(58, 49, 76, 1);
    
                        .bold{
                            font-weight: 400;
                        }
        
                        .color {
                            font-weight: 400;
                            color: rgb(243, 104, 74);
                        }
                    }
        
                    .description {
                        margin-top: 30px;
                        font-family: Raleway;
                        font-style: normal;
                        text-align: left;
                        font-size: 16px;
                        line-height: 150%;
                        color: rgba(0, 0, 0, 0.6);
                    }
        
    
                    .bullet-point-container{
                        display: flex;
                        flex-direction: column;
                        .bullet-item{
                            display: flex;
                            flex-direction: row;
    
                            .icon-container{
                                display: flex;
                                flex-direction: column;
    
                                .buttet-icon{
                                    margin: auto;
                                    width: 13px;
                                    height: 13px;
                                }
                            }
    
                            .bullet-text{
                                margin-left: 8px;
                                font-family: Raleway;
                                font-size: 16px;
                                line-height: 25px;
                                letter-spacing: 0em;
                                text-align: left;
                                color: rgba(0, 0, 0, 0.6);
                            }
                        }
                    }
    
                    .mobile-card-container{
                        display: none;
                        justify-content: center;
                        margin-top: 20px;
                        height: 300px;
    
                        .background-image{
                            position: absolute;
                            display: none;
                            width: 100%;
                            height: 300px;
                        }
    
                        .inner-card {
                            margin: auto;
                            font-family: Raleway;
                            position: relative;
                            font-weight: 400;
                            font-size: 18px;
                            text-align: left;
                            z-index: 1;
                            line-height: 27px;
                            width: 80%;
                            height: fit-content;
                            color: rgba(58, 49, 76, 0.8);
                            margin-left: auto;
                            margin-top: 50px;
                            background-color: white;
                            border-radius: 6px;
                            padding: 0px 20px;
                            box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
            
                            .card-icon {
                                margin-left: 15px;
                                position: absolute;
                                margin-top: -20px;
                                width: 50px;
                                height: 50px;
                            }
            
                            .title {
                                font-family: Raleway;
                                padding-top: 60px;
                                font-size: 22px;
                                font-weight: 300;
                                line-height: 28px;
                                letter-spacing: 0em;
                                text-align: left;
                            }
            
                            .description {
                                font-family: Raleway;
                                margin-top: 0px;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 25px;
                                letter-spacing: 0em;
                                text-align: left;
                            }
                        }
    
                    }
        
                }
        
                .container-image {
                    height: auto;
                    width: 100%;
                    display: flex;
                    
                    .image-container {
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: fit-content;
                        margin-right: auto;
                    
        
                        .circle-decoration {
                            width: 25px;
                            height: 50px;
                            position: absolute;
                            z-index: 1;
                            top: 50px;
                            // -webkit-transform: scaleX(-1);
                            // transform: scaleX(-1);
                        }
        
                        .circle-decoration:nth-child(2) {
                            width: 50px;
                            height: 50px;
                            position: absolute;
                            margin-top: auto;
                            z-index: 0;
                            right: 25px;
                            bottom: 40px;
                        }
        
                        .display-image {
                            position: relative;
                            margin-left: auto;
                            clip-path:  polygon(0px 0px, 100% 0px, 85% 100%, 0% 100%);
                            width: 450px ;
                            height:  350px ;
                            margin-left: -10px;
                        }

                        .inner-card {
                            font-family: Raleway;
                            position: relative;
                            font-weight: 400;
                            font-size: 18px;
                            text-align: left;
                            z-index: 1;
                            line-height: 27px;
                            width: 350px;
                            height: fit-content;
                            color: rgba(58, 49, 76, 0.8);
                            margin-left: auto;
                            margin-right: -50px;
                            margin-top: -150px;
                            background-color: rgba(255, 255, 255, 1);
                            border-radius: 6px;
                            padding: 20px 20px;
                            box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.03);
            
                            .card-icon {
                                margin-left: 10px;
                                position: absolute;
                                margin-top: -40px;
                                width: 50px;
                                height: 50px;
                            }
            
                            .title {
                                font-family: Raleway;
                                padding-top: 30px;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 25px;
                                letter-spacing: 0em;
                                text-align: left;
                                color: black;
                            }
            
                            .description {
                                padding-top: 25px;
                                font-family: Raleway;
                                font-size: 24px;
                                font-weight: 300;
                                line-height: 32px;
                                letter-spacing: 0em;
                                font-style: italic;
                                text-align: left;
                            }
                        }
                    }
                }
        
            }
    
            }
    
     }

 }

 @media only screen and (max-width: map-get($brakpoint, 'md')) {

    .our-services{

        .wrapped-content{
            padding-right: 20px;
            padding-left: 20px;
        }

        .first-section {

            display: flex;
            width: 100%;
            padding-top: 64px;
            padding-bottom: 32px;
            background-color: map.get($colors, "white");

            .section-container {
                width: 100%;
                max-width: map-get($brakpoint, 'sm');
                height: max-content;
                display: flex;
                flex-direction: row;
                margin: auto;

                .container-content {
                    margin-left: 0px;
                    padding-left: 0px;
                    min-width: auto;

                    .label {
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.16em;
                        text-align: left;
                        color:#A09E9E;
                    }

                    .title {
                        font-family: Raleway;
                        font-size: 26px;
                        font-weight: 300;
                        line-height: 34px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: rgb(90, 83, 107);

                        span {
                            color: rgb(243, 104, 74);
                        }
                    }

                    .description {
                        font-family: Raleway;
                        font-weight: 300;
                        margin-top: 20px;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgba(0, 0, 0, 0.6);
                    }

                    .container-image {
                        margin-left: auto;
                        width: 100%;
                        display: flex;

                        .image-container {
                            height: 237px;
                            display: flex;
                            flex-direction: column;
                            position: relative;
                            width: fit-content;
                            margin-left: auto;
                        }

                        .circle-decoration:nth-child(1) {
                            width: 70px;
                            height: 70px;
                            position: absolute;
                            z-index: 1;
                            top: 150px;
                            right: -35px;
                            display: none;
                        }

                        .circle-decoration:nth-child(2) {
                            width: 70px;
                            height: 70px;
                            position: absolute;
                            bottom: 70px;
                            left: -15px;
                            display: none;
                        }

                        .display-image {
                            width: 400px ;
                            height:  100% ;
                            position: relative;
                            margin-left: 0px;
                            clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                        }
                    }

                    .mobile-card-container{
                        display: flex;
                        justify-content: center;
                        margin-top: 20px;
                        height: 300px;
    
                        .background-image{
                            position: absolute;
                            display: flex;
                            width: 100%;
                            height: 300px;
                        }
    
                        .inner-card {
                            margin: auto;
                            font-family: Raleway;
                            position: relative;
                            font-weight: 400;
                            font-size: 18px;
                            text-align: left;
                            z-index: 1;
                            line-height: 27px;
                            width: 80%;
                            height: fit-content;
                            color: rgba(58, 49, 76, 0.8);
                            margin-left: auto;
                            margin-top: 50px;
                            background-color: white;
                            border-radius: 6px;
                            padding: 0px 20px;
                            box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
            
                            .card-icon {
                                margin-left: 15px;
                                position: absolute;
                                margin-top: -20px;
                                width: 50px;
                                height: 50px;
                            }
            
                            .title {
                                font-family: Raleway;
                                padding-top: 60px;
                                font-size: 22px;
                                font-weight: 300;
                                line-height: 28px;
                                letter-spacing: 0em;
                                text-align: left;
                            }
            
                            .description {
                                font-family: Raleway;
                                margin-top: 0px;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 25px;
                                letter-spacing: 0em;
                                text-align: left;
                            }
                        }
    
                    }

                }

                .container-image {
                    display: none;
                }
            }

        }

        .second-section {

            display: flex;
            width: 100%;
            padding-top: 64px;
            padding-bottom: 32px;
            background-color: map.get($colors, "white");

            .section-container {
                width: 100%;
                max-width: map-get($brakpoint, 'sm');
                height: max-content;
                display: flex;
                flex-direction: row;
                margin: auto;

                .container-content {
                    margin-left: 0px;
                    padding-left: 0px;
                    min-width: auto;

                    .label {
                        font-family: Raleway;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.16em;
                        text-align: left;
                    }

                    .title {
                        font-family: Raleway;
                        font-size: 26px;
                        font-weight: 300;
                        line-height: 34px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: rgb(90, 83, 107);

                        span {
                            color: rgb(243, 104, 74);
                        }
                    }

                    .description {
                        font-family: Raleway;
                        font-weight: 300;
                        margin-top: 20px;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgba(0, 0, 0, 0.6);
                    }

                    .container-image {
                        margin-left: auto;
                        width: 100%;
                        display: flex;

                        .image-container {
                            height: 237px;
                            display: flex;
                            flex-direction: column;
                            position: relative;
                            width: fit-content;
                            margin-left: auto;
                        }

                        .circle-decoration:nth-child(1) {
                            width: 70px;
                            height: 70px;
                            position: absolute;
                            z-index: 1;
                            top: 150px;
                            right: -35px;
                            display: none;
                        }

                        .circle-decoration:nth-child(2) {
                            width: 70px;
                            height: 70px;
                            position: absolute;
                            bottom: 70px;
                            left: -15px;
                            display: none;
                        }

                        .display-image {
                            width: 400px ;
                            height:  100% ;
                            position: relative;
                            margin-left: 0px;
                            clip-path: polygon(15% 0px, 100% 0px, 100% 100%, 0% 100%);
                        }
                    }

                    .mobile-card-container{
                        display: flex;
                        justify-content: center;
                        margin-top: 20px;
                        height: 300px;
    
                        .background-image{
                            position: absolute;
                            display: flex;
                            width: 100%;
                            height: 300px;
                        }
    
                        .inner-card {
                            margin: auto;
                            font-family: Raleway;
                            position: relative;
                            font-weight: 400;
                            font-size: 18px;
                            text-align: left;
                            z-index: 1;
                            line-height: 27px;
                            width: 80%;
                            height: fit-content;
                            color: rgba(58, 49, 76, 0.8);
                            margin-left: auto;
                            margin-top: 50px;
                            background-color: white;
                            border-radius: 6px;
                            padding: 0px 20px;
                            box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.03);
            
                            .card-icon {
                                margin-left: 15px;
                                position: absolute;
                                margin-top: -20px;
                                width: 50px;
                                height: 50px;
                            }
            
                            .title {
                                font-family: Raleway;
                                padding-top: 60px;
                                font-size: 22px;
                                font-weight: 300;
                                line-height: 28px;
                                letter-spacing: 0em;
                                text-align: left;
                            }
            
                            .description {
                                font-family: Raleway;
                                margin-top: 0px;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 25px;
                                letter-spacing: 0em;
                                text-align: left;
                            }
                        }
    
                    }

                }

                .container-image {
                    display: none;
                }
            }

        }

    }

}
